<template>
  <b-sidebar id="sidebar-right" right>
    <img src="@/assets/img/icons/family-logo.svg" alt="logo" class="family-logo"/>
    <MenuLinks></MenuLinks>
  </b-sidebar>
</template>

<script>
import MenuLinks from "../Components/MenuLinks";

export default {
  name: 'MobileMenu',
  components: {
    MenuLinks
  }
}
</script>