<template>
  <section class="admin-third-stage">
    <div class="container">
      <div class="row">
        <div class="col-xl-2 col-lg-3 col-md-12">
          <SideBar></SideBar>
        </div>
        <div class="col-xl-10 col-lg-9 col-md-12">
          <h1><img src="@/assets/img/titles/odpowiedzi_do_etapu3.svg" alt="title" class="title-img"/></h1>

          <div class="empty-block" v-if="!thirdStages.length">
            <p>Brak danych odnośnie tego etapu.</p>
          </div>

          <div class="row" v-if="thirdStages.length">
            <div class="col-lg-3 col-md-4 col-sm-6 col-12" v-for="(user, index) in thirdStages" :key="index">
              <div class="question-box">
                <h3>Użytkownik: <router-link :to="{ name: 'adminUser', params: { 'id': user.id } }">{{ user.nick }}</router-link></h3>
                <p>
                  1-{{ user.third_stage.first }};
                  2-{{ user.third_stage.second }};
                  3-{{ user.third_stage.third }};
                  4-{{ user.third_stage.fourth }};
                  5-{{ user.third_stage.fifth }};
                  6-{{ user.third_stage.sixth }};
                  7-{{ user.third_stage.seventh }};
                  8-{{ user.third_stage.eight }};
                  9-{{ user.third_stage.ninth }};
                  10-{{ user.third_stage.tenth }}
                </p>
                <p class="points">
                  Punkty:
                  {{ userThirdStagePoints(
                    user.third_stage.first_point,
                    user.third_stage.second_point,
                    user.third_stage.third_point,
                    user.third_stage.fourth_point,
                    user.third_stage.fifth_point,
                    user.third_stage.sixth_point,
                    user.third_stage.seventh_point,
                    user.third_stage.eight_point,
                    user.third_stage.ninth_point,
                    user.third_stage.tenth_point
                  ) }}
                  <img src="@/assets/img/icons/devil.svg" alt="points"/></p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SideBar from "../Partials/SideBar";

export default {
  name: 'AdminThirdStage',
  components: {
    SideBar
  },
  mounted: function () {
    this.getThirdStages();
  },
  data() {
    return {
      disable: false,
      thirdStages: []
    }
  },
  watch: {
    page (value) {
      this.disable = value > 20
    }
  },
  methods: {
    userThirdStagePoints(first,second,third,fourth,fifth,sixth,seventh,eight,ninth,tenth) {
      this.userThirdStagePoint = 0;
      this.userThirdStagePoint = first + second + third + fourth + fifth + sixth + seventh + eight + ninth + tenth;

      return this.userThirdStagePoint;
    },
    getThirdStages() {
      this.$http.get('all-users', {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
        }
      }).then(res => {
        res.data.forEach(post => {
          if (post.third_stage) {
            this.thirdStages.push(post);
          }
        });
      })
    }
  }
}
</script>
