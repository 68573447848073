<template>
  <section class="user-section user-third-stage">
    <div class="container">
      <div class="row">
        <div class="col-xl-2 col-lg-3 col-md-12">
          <SideBar></SideBar>
        </div>
        <div class="col-xl-8 col-lg-6 col-md-12">
          <div class="user-third-stage-content">
            <h1><img src="@/assets/img/titles/aktualne_zadanie_konkursowe.svg" alt="title" class="title-img"/></h1>
            <h2 class="head-h2">3. Quiz Family Challenge</h2>
            <p class="desc">Odpowiedz na pytania dotyczące naszych produktów i wykaż się znajomością marki Diablo Chairs. Przed Tobą dziesięć pytań, możesz wybierać spośród odpowiedzi a), b), c) oraz d).</p>

            <div class="welcome-content" v-if="!isActive">
              <p class="head">Maksymalna liczba diabełków do zdobycia:</p>

              <div class="devils">
                <img src="@/assets/img/icons/devil.svg" alt="devil" class="devil-img" v-for="i in 10" :key="i"/>
              </div>

              <button @click="activeStage" type="button" class="main-button start-stage">Biorę udział</button>
            </div>

            <div class="stage-content" v-if="isActive">

              <div class="errors">
                <p v-if="error" class="error-label">{{ error }}</p>
                <ul class="errors-list">
                  <li class="error-label" v-for="(err, index) in errors" :key="index">{{ err[0] }}</li>
                </ul>
              </div>

              <form @submit.prevent="answer()">
                <div class="question">
                  <div class="question-text">
                    {{ questions[index].text }}
                  </div>
                  <div class="question-choices">
                    <div class="question-choice" v-for="(choice, idx) in questions[index].choices" :key="idx">
                      <div class="pretty p-default p-round">
                        <input @change="nextQuestion()" type="radio" :id="choice.val" :name="`q${questions[index].id}`" :value="choice.val" v-model="questions[index].response">
                        <div class="state">
                          <label>{{ choice.text }}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="line-content">
                  <div class="line"></div>

                  <div class="step-content">
                    <button type="button" @click="chooseQuestion(i)" v-for="(n, i) in 10" :class="{ 'active': i === index }" :key="i"></button>
                  </div>
                </div>

                <div class="stage-buttons">
                  <button @click="activeStage" type="button" class="main-button">Anuluj</button>
                  <button type="submit" class="main-button">Prześlij odpowiedzi</button>
                </div>
              </form>

            </div>
          </div>
        </div>
        <div class="offset-xl-2 offset-lg-3 d-md-none"></div>
      </div>
    </div>
  </section>
</template>

<script>
import SideBar from "../Partials/SideBar";

export default {
  name: 'UserThirdStage',
  components: {
    SideBar
  },
  data() {
    return {
      id: '',
      description: '',
      error: '',
      errors: [],
      isActive: false,
      index: 0,
      questions: [{
        id: 0,
        text: '1) Która kultowa gra zainspirowała markę Diablo Chairs do stworzenia jednego z foteli gamingowych?',
        choices: [{
          text: 'a) Sims',
          val: 'a'
        }, {
          text: 'b) Minecraft', // true
          val: 'b'
        }, {
          text: 'c) Fortnite',
          val: 'c'
        }, {
          text: 'd) Warcraft',
          val: 'd'
        }],
        response: ''
      }, {
        id: 1,
        text: '2) Który z foteli gamingowych zdobył tytuł fotela roku w Plebiscycie Diablo Chairs 2020?',
        choices: [{
          text: 'a) X–Horn',
          val: 'a'
        }, {
          text: 'b) X-Fighter',
          val: 'b'
        }, {
          text: 'c) X-Player 2.0', // true
          val: 'c'
        }, {
          text: 'd) X-Gamer',
          val: 'd'
        }],
        response: ''
      }, {
        id: 2,
        text: '3) Ile kółek maja nasze fotele i z jakiego materiału są wykonane?',
        choices: [{
          text: 'a) 5 kółek z włókna typu E',
          val: 'a'
        }, {
          text: 'b) 6 kółek poliuretanowych',
          val: 'b'
        }, {
          text: 'c) 5 kółek z włókna poliwęglanowego',
          val: 'c'
        }, {
          text: 'd) 5 kółek kauczukowych', // true
          val: 'd'
        }],
        response: ''
      }, {
        id: 3,
        text: '4) Jaka jest szerokość biurka Diablo X-Mate 1400?',
        choices: [{
          text: 'a) 140 cm', // true
          val: 'a'
        }, {
          text: 'b) 100 cm',
          val: 'b'
        }, {
          text: 'c) 80 cm',
          val: 'c'
        }, {
          text: 'd) 160 cm',
          val: 'd'
        }],
        response: ''
      }, {
        id: 4,
        text: '5) W ilu płaszczyznach można regulować podłokietniki w modelu X-Ray?',
        choices: [{
          text: 'a) 1',
          val: 'a'
        }, {
          text: 'b) 2',
          val: 'b'
        }, {
          text: 'c) 3',
          val: 'c'
        }, {
          text: 'd) 4', // true
          val: 'd'
        }],
        response: ''
      }, {
        id: 5,
        text: '6) Część środków uzyskana ze sprzedaży jednego z gadżetów dostępnych na stronie Diablo Chairs przekazywana jest na cele charytatywne. Wskaż gadżet z naszej oferty o którym mowa:',
        choices: [{
          text: 'a) Długopis Diablo Chairs',
          val: 'a'
        }, {
          text: 'b) Smycz do kluczy Diablo Chairs\n',
          val: 'b'
        }, {
          text: 'c) Podkładka Gamingowa LED Diablo Chairs\n',
          val: 'c'
        }, {
          text: 'd) Opaska na rękę Diablo Chairs', // true
          val: 'd'
        }],
        response: ''
      }, {
        id: 6,
        text: '7) Który z rozmiarów foteli Diablo Chairs zaprojektowany jest z myślą o graczach powyżej 170 cm wzrostu?',
        choices: [{
          text: 'a) Normal',
          val: 'a'
        }, {
          text: 'b) Medium',
          val: 'b'
        }, {
          text: 'c) King Size', // true
          val: 'c'
        }, {
          text: 'd) Standard Size',
          val: 'd'
        }],
        response: ''
      }, {
        id: 7,
        text: '8) Z podanych poniżej odpowiedzi wybierz hasło marki Diablo Chairs:',
        choices: [{
          text: 'a) Diablo Chairs i jesteś level wyżej',
          val: 'a'
        }, {
          text: 'b) Piekielna wygoda i unikatowy design',
          val: 'b'
        }, {
          text: 'c) Design w Diabelskim stylu',
          val: 'c'
        }, {
          text: 'd) Diabeł tkwi w szczegółach', // true
          val: 'd'
        }],
        response: ''
      }, {
        id: 8,
        text: '9) Ile wersji kolorystycznych ma model Diablo X-Ray?',
        choices: [{
          text: 'a) 1',
          val: 'a'
        }, {
          text: 'b) 2',
          val: 'b'
        }, {
          text: 'c) 3', // true
          val: 'c'
        }, {
          text: 'd) 4',
          val: 'd'
        }],
        response: ''
      }, {
        id: 9,
        text: '10) Ile modeli serii V jest obecnie w ofercie?',
        choices: [{
          text: 'a) 1',
          val: 'a'
        }, {
          text: 'b) 2',
          val: 'b'
        }, {
          text: 'c) 3',
          val: 'c'
        }, {
          text: 'd) 4', // true
          val: 'd'
        }],
        response: ''
      }]
    }
  },
  mounted: function () {
    this.getId();
    this.protectedDate();
  },
  methods: {
    activeStage() {
      this.isActive = !this.isActive;
    },
    chooseQuestion(index) {
      this.index = index;
    },
    nextQuestion() {
      setTimeout(() => this.index++, 500);
    },
    protectedDate() {
      let now = new Date();
      let start = new Date('2021-06-09');

      if (now.getTime() > start.getTime()) {
        this.$router.push({ name: 'userTasks' });
      }
    },
    getId() {
      this.$http.get('me', {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
        }
      }).then(({ data }) => {
        this.id = data.me.id;

        if (data.me.third_stage) {
          this.$router.push({ name: 'userTasks' });
        }
      })
    },
    answer() {
      const form = new FormData()

      form.set('user_id', this.id)

      form.set('first', this.questions[0].response)
      form.set('second', this.questions[1].response)
      form.set('third', this.questions[2].response)
      form.set('fourth', this.questions[3].response)
      form.set('fifth', this.questions[4].response)
      form.set('sixth', this.questions[5].response)
      form.set('seventh', this.questions[6].response)
      form.set('eight', this.questions[7].response)
      form.set('ninth', this.questions[8].response)
      form.set('tenth', this.questions[9].response)

      this.$http.post('third-stage',
          form,
          {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'multipart/form-data',
            }
          })
          .then(({ data }) => {
            if (data.success) {
              this.$router.push({ name: 'userSummary' });
            } else {
              this.error = data.error;
              this.errors = data.errors;
            }
          })
    }
  }
}
</script>
