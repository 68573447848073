<template>
  <section class="cookies-bar" v-if="show">
   <div class="container">
     <div class="row">
       <div class="col-lg-12">
         <p class="cookie-info">
           <span>Ta strona używa plików Cookies. Dowiesz się więcej o celu ich używania i możliwości zmiany ustawień Cookies w przeglądarce. <a href="https://diablochairs.com/polityka-prywatnosci" target="_blank">więcej</a></span>
           <button type="button" class="close-btn" @click="hide">
             <img src="@/assets/img/icons/close.svg" alt="close"/>
           </button>
         </p>
       </div>
     </div>
   </div>
  </section>
</template>

<script>
export default {
  name: 'CookiesBar',
  data() {
    return {
      show: true
    }
  },
  methods: {
    hide() {
      this.show = false;
    }
  }
}
</script>
