<template>
  <section class="user-section document">
    <div class="container">
      <div class="row">
        <div class="col-xl-2 col-lg-3 col-md-12">
          <SideBar></SideBar>
        </div>
        <div class="col-xl-8 col-lg-6 col-md-12">
          <div class="document-content">
            <h1><img src="@/assets/img/titles/polityka_prywatnosci.svg" alt="title" class="title-img"/></h1>

            <p class="privacy-policy">W celu zapoznania się z Polityką Prywatność kliknij <a href="https://diablochairs.com/polityka-prywatnosci" target="_blank">tutaj</a>.</p>

            <div class="document-buttons">
              <router-link :to="{ name: 'userStart' }" class="main-button">Powrót</router-link>
            </div>

          </div>
        </div>
        <div class="offset-xl-2 offset-lg-3 d-md-none"></div>
      </div>
    </div>
  </section>
</template>

<script>
import SideBar from "../Partials/SideBar";

export default {
  name: 'PrivacyPolicy',
  components: {
    SideBar
  }
}
</script>
