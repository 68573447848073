<template>
  <div id="app" :style="[isStart ? {'background-image': 'url(' + require('./assets/img/bg/auth-bg2.png') + ')'} : {'background-image': 'url(' + require('./assets/img/bg/panel-bg.png') + ')'}]">
    <header>
      <CookiesBar></CookiesBar>
      <Header v-if="!isStart"></Header>
      <StartHeader v-if="isStart"></StartHeader>
    </header>

    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>

    <footer>
      <Footer></Footer>
    </footer>

    <Social></Social>
  </div>
</template>

<script>
import Header from "./components/Partials/Header";
import StartHeader from "./components/Partials/StartHeader";
import Footer from "./components/Partials/Footer";
import CookiesBar from "./components/Partials/CookiesBar";
import Social from "./components/Partials/Social";

export default {
  name: 'App',
  components: {
    CookiesBar,
    Header,
    StartHeader,
    Footer,
    Social
  },
  computed: {
    isStart() {
      if (this.$route.name === 'start' || this.$route.name === 'login' || this.$route.name === 'register' || this.$route.name === 'adminLogin') {
        return true;
      }

      return false;
    }
  }
}
</script>

<style lang="scss">
  @import './assets/scss/fonts/font.css';
  @import './assets/scss/all';
</style>