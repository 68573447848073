import Vue from "vue";
import VueRouter from "vue-router";

import routes from "../router/routes";
import { isLoggedIn } from "../utils/jwt";
import { isAdminLoggedIn } from "../utils/admin-jwt";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: "history",
    linkExactActiveClass: 'active',
    routes
});

router.beforeEach((to, from, next) => {
    if(to.meta.auth === true) {
        if( isLoggedIn() ) {
            next();
        } else {
            next({ name: "start" });
        }
    } else {
        next();
    }

    if(to.meta.loggedIn === true) {
        if (isLoggedIn()) {
            next({ name: "userStart" });
        } else {
            next();
        }
    }

    if(to.meta.authAdmin === true) {
        if( isAdminLoggedIn() ) {
            next();
        } else {
            next({ name: "adminLogin" });
        }
    } else {
        next();
    }

    if(to.meta.adminLoggedIn === true) {
        if (isAdminLoggedIn()) {
            next({ name: "adminStart" });
        } else {
            next();
        }
    }
});

export default router;