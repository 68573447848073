<template>
  <section class="user-section user-tasks">
    <div class="container">
      <div class="row">
        <div class="col-xl-2 col-lg-3 col-md-12">
          <SideBar></SideBar>
        </div>
        <div class="col-xl-8 col-lg-6 col-md-12">
          <div class="user-tasks-content">
            <h1><img src="@/assets/img/titles/moje_zadania.svg" alt="title" class="title-img"/></h1>
            <p class="desc">Aby rywalizować o najlepszy fotel gamingowy na świecie oraz wyjątkowe gadżety, weź udział w każdym z trzech wyzwań naszego konkursu. Nie przegap ich, bo na zwycięzców czekają naprawdę wyjątkowe nagrody.</p>

            <div class="row">

              <div class="col-lg-4 col-md-12">
                <button disabled class="task-box" v-if="first_stage">
                  <img src="@/assets/img/icons/check.svg" alt="icon" class="icon"/>
                  <span>Ukończono!</span>
                </button>

                <button @click="goToFirstStage" class="task-box active" v-if="firstStageAvailable && !first_stage">
                  <img src="@/assets/img/icons/question.svg" alt="icon" class="icon question"/>
                  <img src="@/assets/img/icons/question-hover.svg" alt="icon" class="icon question hover"/>
                  <span>Zagraj teraz!</span>
                </button>

                <button disabled class="task-box not-available" v-if="!firstStageAvailable && !first_stage">
                  <img src="@/assets/img/icons/not-available.svg" alt="icon" class="icon"/>
                  <span class="not-available">Etap dostępny od 24.05!</span>
                </button>
              </div>

              <div class="col-lg-4 col-md-12">
                <button disabled class="task-box" v-if="second_stage">
                  <img src="@/assets/img/icons/check.svg" alt="icon" class="icon"/>
                  <span>Ukończono!</span>
                </button>

                <button @click="goToSecondStage" class="task-box active" v-if="secondStageAvailable && !second_stage">
                  <img src="@/assets/img/icons/question.svg" alt="icon" class="icon question"/>
                  <img src="@/assets/img/icons/question-hover.svg" alt="icon" class="icon question hover"/>
                  <span>Zagraj teraz!</span>
                </button>

                <button disabled class="task-box not-available" v-if="!secondStageAvailable && !second_stage">
                  <img src="@/assets/img/icons/not-available.svg" alt="icon" class="icon"/>
                  <span class="not-available">Etap dostępny od 01.06!</span>
                </button>
              </div>

              <div class="col-lg-4 col-md-12">
                <button disabled class="task-box" v-if="third_stage">
                  <img src="@/assets/img/icons/check.svg" alt="icon" class="icon"/>
                  <span>Ukończono!</span>
                </button>

                <button @click="goToThirdStage" class="task-box active" v-if="thirdStageAvailable && !third_stage">
                  <img src="@/assets/img/icons/question.svg" alt="icon" class="icon question"/>
                  <img src="@/assets/img/icons/question-hover.svg" alt="icon" class="icon question hover"/>
                  <span>Zagraj teraz!</span>
                </button>

                <button disabled class="task-box not-available" v-if="!thirdStageAvailable && !third_stage">
                  <img src="@/assets/img/icons/not-available.svg" alt="icon" class="icon"/>
                  <span class="not-available">Etap dostępny od 09.06!</span>
                </button>
              </div>

            </div>

            <router-link :to="{ name: 'userStart' }" class="main-button back">Powrót</router-link>
          </div>
        </div>
        <div class="offset-xl-2 offset-lg-3 d-md-none"></div>
      </div>
    </div>
  </section>
</template>

<script>
import SideBar from "../Partials/SideBar";
import { FirstStage, SecondStage, ThirdStage } from "../../models/models";

export default {
  name: 'userTasks',
  components: {
    SideBar
  },
  data() {
    return {
      first_stage: FirstStage,
      second_stage: SecondStage,
      third_stage: ThirdStage,
      firstStageAvailable: false,
      secondStageAvailable: false,
      thirdStageAvailable: false
    }
  },
  mounted: function () {
    this.firstStageDate();
    this.secondStageDate();
    this.thirdStageDate();
    this.getStages();
  },
  methods: {
    firstStageDate() {
      let now = new Date();
      let start = new Date('2021-05-24');

      if (now.getTime() >= start.getTime()) {
        this.firstStageAvailable = true;
      }
    },
    secondStageDate() {
      let now = new Date();
      let start = new Date('2021-05-31');

      if (now.getTime() >= start.getTime()) {
        this.secondStageAvailable = true;
      }
    },
    thirdStageDate() {
      let now = new Date();
      let start = new Date('2021-06-09');

      if (now.getTime() >= start.getTime()) {
        this.thirdStageAvailable = true;
      }
    },
    goToFirstStage() {
      this.$router.push({ name: 'userFirstStage' });
    },
    goToSecondStage() {
      this.$router.push({ name: 'userSecondStage' });
    },
    goToThirdStage() {
      this.$router.push({ name: 'userThirdStage' });
    },
    getStages() {
      this.$http.get('me', {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
        }
      }).then(({ data }) => {
        this.first_stage = data.me.first_stage;
        this.second_stage = data.me.second_stage;
        this.third_stage = data.me.third_stage;
      })
    }
  }
}
</script>