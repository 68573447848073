<template>
  <vue-modal name="changePasswordModal">
    <h2 slot="header"><img src="@/assets/img/titles/zmien_swoje_haslo.svg" alt="title" class="title-img"/></h2>

    <div class="errors">
      <p v-if="error" class="error-label">{{ error }}</p>
      <ul class="errors-list">
        <li class="error-label" v-for="(err, index) in errors" :key="index">{{ err[0] }}</li>
      </ul>
    </div>

    <form @submit.prevent="changePassword">
      <label for="old_password">Stare hasło</label>
      <input type="password" id="old_password" v-model="old_password"/>

      <label for="new_password">Nowe hasło</label>
      <input type="password" id="new_password" v-model="new_password"/>

      <label for="confirm_password">Powtórz nowe hasło</label>
      <input type="password" id="confirm_password" v-model="confirm_password"/>

      <div class="modal-buttons change-password">
        <button type="button" class="main-button" @click="closeChangePasswordModal">Powrót</button>
        <button type="submit" class="main-button accept">Zapisz</button>
      </div>
    </form>

  </vue-modal>
</template>

<script>
  export default {
    name: 'ChangePassword',
    data() {
      return {
        old_password: '',
        new_password: '',
        confirm_password: '',
        error: '',
        errors: [],
        me: []
      }
    },
    methods: {
      closeChangePasswordModal() {
        this.$modals.hide("changePasswordModal");
      },
      changePassword() {
        const form = new FormData()

        form.set('old_password', this.old_password)
        form.set('new_password', this.new_password)
        form.set('confirm_password', this.confirm_password)

        this.$http.post('change-password',
            form,
            {
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data',
              }
            })
            .then(({ data }) => {
              if (data.success) {
                this.closeChangePasswordModal();
              } else {
                this.error = data.error;
                this.errors = data.errors;
              }
            })
      }
    }
  }
</script>