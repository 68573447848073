const User = {
    id: '',
    firstname: '',
    lastname: '',
    login: '',
    nick: '',
    email: '',
    phone: '',
    agree: '',
    marketing: '',
    newsletter: ''
}

const FirstStage = {
    id: '',
    description: '',
    points: '',
}

const SecondStage = {
    id: '',
    image: '',
    link: '',
    points: '',
}

const ThirdStage = {
    id: '',
    first: '',
    second: '',
    third: '',
    fourth: '',
    fifth: '',
    sixth: '',
    seventh: '',
    eight: '',
    ninth: '',
    tenth: '',
    first_point: '',
    second_point: '',
    third_point: '',
    fourth_point: '',
    fifth_point: '',
    sixth_point: '',
    seventh_point: '',
    eight_point: '',
    ninth_point: '',
    tenth_point: ''
}

export { User, FirstStage, SecondStage, ThirdStage }