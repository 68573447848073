import router from "../router";

export function getAdminToken() {
    return window.localStorage.getItem("adminToken");
}

export function adminLogin(token) {
    window.localStorage.clear();
    window.localStorage.setItem("adminToken", token);
    router.push({ name: 'adminStart' });
}

export function adminLogout() {
    window.localStorage.clear();
    router.push({ name: 'adminLogin' });
}

export function isAdminLoggedIn() {
    return getAdminToken();
}