<template>
  <section class="admin-first-stage">
    <div class="container">
      <div class="row">
        <div class="col-xl-2 col-lg-3 col-md-12">
          <SideBar></SideBar>
        </div>
        <div class="col-xl-10 col-lg-9 col-md-12">
          <h1><img src="@/assets/img/titles/odpowiedzi_do_etapu1.svg" alt="title" class="title-img"/></h1>

          <div class="empty-block" v-if="!firstStages.length">
            <p>Brak danych odnośnie tego etapu.</p>
          </div>

          <div class="row" v-if="firstStages.length">
            <div class="col-lg-6 col-md-12" v-for="(user, index) in firstStages" :key="index">
              <div class="question-box">
                <h3>Użytkownik: <router-link :to="{ name: 'adminUser', params: { 'id': user.id } }">{{ user.nick }}</router-link></h3>
                <p class="desc">{{ user.first_stage.description }}</p>
                <p class="points" v-if="user.first_stage.points === 0">
                  <span>Punkty:</span>
                  <button @click="assignFirstStagePoints(user.id, i)" class="points-btn" type="button" v-for="i in 11" :key="i" :content="'Przyznaj ' + i + ' punktów.'" v-tippy="{ placement : 'top',  arrow: true }">
                    <img src="@/assets/img/icons/devil.svg" alt="points"/>
                  </button>
                </p>
                <p class="points user-points" v-else><span>Punkty: {{ user.first_stage.points }}</span> <img src="@/assets/img/icons/devil.svg" alt="points"/></p>

                <p class="points" v-if="user.first_stage.points !== 0 && first_stage_show">
                  <span>Punkty:</span>
                  <button @click="assignFirstStagePoints(user.id, i)" class="points-btn" type="button" v-for="i in 11" :key="i" :content="'Przyznaj ' + i + ' punktów.'" v-tippy="{ placement : 'top',  arrow: true }">
                    <img src="@/assets/img/icons/devil.svg" alt="points"/>
                  </button>
                </p>

                <button @click="toggleFirstStage" class="main-button change-btn" v-if="user.first_stage.points !== 0">
                  <span v-if="first_stage_show">Anuluj zmianę</span>
                  <span v-if="!first_stage_show">Zmień ilość punktów</span>
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SideBar from "../Partials/SideBar";

export default {
  name: 'AdminFirstStage',
  components: {
    SideBar
  },
  mounted: function () {
    this.getFirstStages();
  },
  data() {
    return {
      first_stage_show: false,
      firstStages: [],
    }
  },
  watch: {
    page (value) {
      this.disable = value > 20
    }
  },
  methods: {
    toggleFirstStage() {
      this.first_stage_show = !this.first_stage_show;
    },
    getFirstStages() {
      this.$http.get('all-users', {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
        }
      }).then(res => {
        res.data.forEach(post => {
          if (post.first_stage) {
            this.firstStages.push(post);
          }
        });
      })
    },
    assignFirstStagePoints(user_id, points) {
      const form = new FormData()

      form.set('user_id', user_id)
      form.set('points', points)

      this.$http.post('set-first-stage-points',
          form,
          {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'multipart/form-data',
            }
          })
          .then(({ data }) => {
            if (data.success) {
              this.firstStages = [];
              this.getFirstStages();
              this.first_stage_show = false;
            }
          })
    },
  }
}
</script>
