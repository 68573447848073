// Packages
import Vue from 'vue'
import Vuex from 'vuex'
import {BootstrapVue} from 'bootstrap-vue'
import VueTippy, { TippyComponent } from "vue-tippy"
import VueModal from "vue2-modal";
import VueResource from "vue-resource"
import PrettyCheckbox from 'pretty-checkbox-vue'
import ScrollLoader from 'vue-scroll-loader'
import axios from "axios"
import VueAxios from "vue-axios"
import { getToken } from "./utils/jwt";

// Files
import App from './App.vue'
import router from "./router"

Vue.config.productionTip = true
Vue.prototype.$http = axios


const axiosCustom = axios.create({
  baseURL: "https://challenge-api.fkowalik.pl/api/"
  // baseURL: "http://localhost:8000/api/"
});

axiosCustom.interceptors.response.use(res => res, error => {
  if(error.response.status === 401) {
    router.push({ name: "start" });
  } else {
    return Promise.reject(error);
  }
});

axiosCustom.interceptors.request.use(config => {
  const token = getToken();

  if(token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
}, error => Promise.reject(error));

Vue.use(BootstrapVue)
Vue.use(Vuex);
Vue.use(VueResource);
Vue.use(VueTippy);
Vue.use(VueModal);
Vue.use(PrettyCheckbox);
Vue.use(ScrollLoader);

Vue.use(VueAxios, axiosCustom);
Vue.component("tippy", TippyComponent);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')