<template>
  <section class="admin-users">
    <div class="container">
      <div class="row">
        <div class="col-xl-2 col-lg-3 col-md-12">
          <SideBar></SideBar>
        </div>
        <div class="col-xl-10 col-lg-9 col-md-12">


          <h1><img src="@/assets/img/titles/uzytkownicy.svg" alt="title" class="title-img"/></h1>

          <div class="empty-block" v-if="!users.length">
            <p>Brak danych odnośnie tego etapu.</p>
          </div>

          <div class="table-responsive" v-if="users.length">
            <table class="table table-stripe" ref="table">
            <thead>
            <tr>
              <th scope="col">Lp</th>
              <th scope="col">Nick</th>
              <th scope="col">Imię</th>
              <th scope="col">Nazwisko</th>
              <th scope="col">Etapy</th>
              <th scope="col">Etap 1</th>
              <th scope="col">Etap 2</th>
              <th scope="col">Etap 3</th>
              <th scope="col">Suma</th>
              <th scope="col">Szczegóły</th>
            </tr>
            </thead>
            <tbody>
              <tr v-for="(user, index) in users" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ user.nick }}</td>
                <td>{{ user.firstname }}</td>
                <td>{{ user.lastname }}</td>
                <td>{{ allStages(user) }}/3</td>

                <td v-if="user.first_stage">{{ user.first_stage.points }}</td>
                <td v-else>0</td>

                <td v-if="user.second_stage">{{ user.second_stage.points }}</td>
                <td v-else>0</td>


                <td v-if="user.third_stage">{{ thirdPoints(
                    user,
                    user.third_stage.first_point,
                    user.third_stage.second_point,
                    user.third_stage.third_point,
                    user.third_stage.fourth_point,
                    user.third_stage.fifth_point,
                    user.third_stage.sixth_point,
                    user.third_stage.seventh_point,
                    user.third_stage.eight_point,
                    user.third_stage.ninth_point,
                    user.third_stage.tenth_point
                )}}</td>
                <td v-else>0</td>


                <td v-if="user.third_stage">{{ allPoints(
                    user,
                    user.third_stage.first_point,
                    user.third_stage.second_point,
                    user.third_stage.third_point,
                    user.third_stage.fourth_point,
                    user.third_stage.fifth_point,
                    user.third_stage.sixth_point,
                    user.third_stage.seventh_point,
                    user.third_stage.eight_point,
                    user.third_stage.ninth_point,
                    user.third_stage.tenth_point
                ) }}</td>
                <td v-else>0</td>

                <td>
                  <router-link :to="{ name: 'adminUser', params: { 'id': user.id } }" class="more-link">więcej</router-link>
                </td>
              </tr>
            </tbody>
          </table>
          </div>
          <scroll-loader :loader-method="getUsers" :loader-disable="disable"></scroll-loader>

        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SideBar from "../Partials/SideBar";

export default {
  name: 'AdminUsers',
  components: {
    SideBar
  },
  mounted: function () {
    this.getUsers();
  },
  data() {
    return {
      users: [],
      disable: false,
      page: 1,
      pageSize: 30
    }
  },
  watch: {
    page (value) {
      this.disable = value > 20
    }
  },
  methods: {
    allPoints(user,first,second,third,fourth,fifth,sixth,seventh,eight,ninth,tenth) {
      let firstPoints = 0;
      let secondPoints = 0;
      let thirdPoints = 0;

      if (user.first_stage) {
        firstPoints = user.first_stage.points;
      }

      if (user.second_stage) {
        secondPoints = user.second_stage.points;
      }

      if (user.third_stage) {
        thirdPoints = first + second + third + fourth + fifth + sixth + seventh + eight + ninth + tenth;
      }

      return firstPoints + secondPoints + thirdPoints;
    },
    thirdPoints(user,first,second,third,fourth,fifth,sixth,seventh,eight,ninth,tenth) {
      let thirdPoints = 0;

      if (user.third_stage) {
        thirdPoints = first + second + third + fourth + fifth + sixth + seventh + eight + ninth + tenth;
      }

      return thirdPoints;
    },
    allStages(user) {
      let stages = 0;

      if (user.first_stage) {
        stages++;
      }

      if (user.second_stage) {
        stages++;
      }

      if (user.third_stage) {
        stages++;
      }

      return stages;
    },
    getUsers() {
      this.$http.get('users', {
        params: {
          'page': this.page++,
          'per_page': this.pageSize
        },
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
        }
      }).then(res => {
        res.data.data.forEach(post => {
          this.users.push(post)
        });

        this.disable = res.data.length > this.pageSize
      })
    },
  }
}
</script>
