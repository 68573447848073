<template>
  <section class="start-section">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="start-content">
            <img src="@/assets/img/icons/family-logo.svg" class="family-logo" alt="diablochairs-logo"/>
            <p class="date">24.05.2021 - 30.06.2021</p>
            <p class="desc">Weź udział w Diablo Family Challenge! Sprawdź na ile znasz markę #diablochairs, zabłyśnij kreatywnością i zabierz nas do świata swojej ulubionej gry! Zwycięzcy naszej zabawy zgarną masę wyjątkowych nagród, w tym swój wymarzony fotel gamingowy Diablo Chairs. <router-link :to="{ name: 'regulation' }">Sprawdź regulamin</router-link></p>

            <router-link :to="{ name: 'login' }" class="main-button">Wchodzę</router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Start'
}
</script>
