<template>
  <section class="not-found">
    <div class="container">
      <div class="row">
        <div class="col-xl-12">
          <div class="not-found-content">
            <h1><img src="@/assets/img/titles/nic_nie_znaleziono.svg" alt="title" class="title-img"/></h1>
            <p class="desc">Przykro nam, nie znaleziono strony, której szukasz, kliknij przycisk poniżej, aby przejść do strony głównej!</p>

            <router-link :to="{ name: 'start' }" class="main-button back">Powrót do strony głównej</router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>
