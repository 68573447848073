// Home
import Start from "../components/Start";

// Auth
import Login from "../components/Auth/Login";
import Register from "../components/Auth/Register";

// Pages
import Regulation from "../components/Pages/Regulation";
import PrivacyPolicy from "../components/Pages/PrivacyPolicy";

// Models
import UserDashboard from "../components/UserDashboard";
import UserStart from "../components/User/UserStart";
import UserFirstStage from "../components/User/UserFirstStage";
import UserSecondStage from "../components/User/UserSecondStage";
import UserThirdStage from "../components/User/UserThirdStage";
import UserAccount from "../components/User/UserAccount";
import UserAwards from "../components/User/UserAwards";
import UserTasks from "../components/User/UserTasks";
import UserThanks from "../components/User/UserThanks";
import UserSummary from "../components/User/UserSummary";

// Admin
import AdminDashboard from "../components/AdminDashboard";
import AdminLogin from "../components/Admin/AdminLogin";
import AdminStart from "../components/Admin/AdminStart";
import AdminFirstStage from "../components/Admin/AdminFirstStage";
import AdminSecondStage from "../components/Admin/AdminSecondStage";
import AdminThirdStage from "../components/Admin/AdminThirdStage";
import AdminUsers from "../components/Admin/AdminUsers";
import AdminUser from "../components/Admin/AdminUser";

// NotFound
import NotFound from "../components/NotFound/NotFound";

const routes = [
    // Home
    { path: '', name: 'start', component: Start, meta: { loggedIn: true, adminLoggedIn: true } },

    // Pages
    { path: '/regulamin', name: 'regulation', component: Regulation },
    { path: '/polityka-prywatnosci', name: 'privacyPolicy', component: PrivacyPolicy },

    // Auth
    { path: '/logowanie', name: 'login', component: Login, meta: { loggedIn: true } },
    { path: '/rejestracja', name: 'register', component: Register, meta: { loggedIn: true } },

    // Models
    {
        path: '/panel',
        component: UserDashboard,
        children: [
            { path: '', name: 'userStart', component: UserStart, meta: { auth: true }, },
            { path: 'moje-zadania', name: 'userTasks', component: UserTasks, meta: { auth: true }, },
            { path: 'pierwszy-etap', name: 'userFirstStage', component: UserFirstStage, meta: { auth: true }, },
            { path: 'drugi-etap', name: 'userSecondStage', component: UserSecondStage, meta: { auth: true }, },
            { path: 'trzeci-etap', name: 'userThirdStage', component: UserThirdStage, meta: { auth: true }, },
            { path: 'moje-konto', name: 'userAccount', component: UserAccount, meta: { auth: true }, },
            { path: 'nagrody', name: 'userAwards', component: UserAwards, meta: { auth: true }, },
            { path: 'dziekujemy', name: 'userThanks', component: UserThanks, meta: { auth: true }, },
            { path: 'podsumowanie', name: 'userSummary', component: UserSummary, meta: { auth: true }, }
        ]
    },

    // Admin
    {
        path: '/admin',
        component: AdminDashboard,
        children: [
            { path: '', name: 'adminLogin', component: AdminLogin, meta: { adminLoggedIn: true } },
            { path: 'start', name: 'adminStart', component: AdminStart, meta: { authAdmin: true } },
            { path: 'pierwszy-etap', name: 'adminFirstStage', component: AdminFirstStage, meta: { authAdmin: true } },
            { path: 'drugi-etap', name: 'adminSecondStage', component: AdminSecondStage, meta: { authAdmin: true } },
            { path: 'trzeci-etap', name: 'adminThirdStage', component: AdminThirdStage, meta: { authAdmin: true } },
            { path: 'uzytkownicy', name: 'adminUsers', component: AdminUsers, meta: { authAdmin: true } },
            { path: 'uzytkownik/:id', name: 'adminUser', component: AdminUser, meta: { authAdmin: true } }
        ]
    },

    // NotFound
    { path: '*', name: 'not_found', component: NotFound },
];

export default routes;