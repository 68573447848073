<template>
  <section class="user-section user-first-stage">
    <div class="container">
      <div class="row">
        <div class="col-xl-2 col-lg-3 col-md-12">
          <SideBar></SideBar>
        </div>
        <div class="col-xl-8 col-lg-6 col-md-12">
          <div class="user-first-stage-content">
            <h1><img src="@/assets/img/titles/aktualne_zadanie_konkursowe.svg" alt="title" class="title-img"/></h1>
            <h2 class="head-h2">1. Pokaż swoją kreatywność</h2>
            <p class="desc">W minimum trzech zdaniach opisz nam, którą postać z gry i dlaczego najbardziej przypomina Ci Twoja mama.</p>

            <div class="welcome-content" v-if="!isActive">
              <p class="head">Maksymalna liczba diabełków do zdobycia:</p>

              <div class="devils">
                <img src="@/assets/img/icons/devil.svg" alt="devil" class="devil-img" v-for="i in 11" :key="i"/>
              </div>

              <button @click="activeStage" type="button" class="main-button start-stage">Biorę udział</button>
            </div>

            <div class="stage-content" v-if="isActive">

              <div class="errors">
                <p v-if="error" class="error-label">{{ error }}</p>
                <ul class="errors-list">
                  <li class="error-label" v-for="(err, index) in errors" :key="index">{{ err[0] }}</li>
                </ul>
              </div>

              <form @submit.prevent="answer">
                <label for="descripton">Opis postaci z gry</label>
                <textarea id="descripton" v-model="description"></textarea>

                <div class="stage-buttons">
                  <button @click="activeStage" type="button" class="main-button">Anuluj</button>
                  <button type="submit" class="main-button">Odpowiedz</button>
                </div>
              </form>
            </div>

          </div>
        </div>
        <div class="offset-xl-2 offset-lg-3 d-md-none"></div>
      </div>
    </div>
  </section>
</template>

<script>
import SideBar from "../Partials/SideBar";

export default {
  name: 'UserFirstStage',
  components: {
    SideBar
  },
  data() {
    return {
      error: '',
      errors: [],
      id: '',
      description: '',
      isActive: false
    }
  },
  mounted: function () {
    this.getId();
    this.protectedDate();
  },
  methods: {
    activeStage() {
      this.isActive = !this.isActive;
    },
    answer() {
      const form = new FormData()

      form.set('user_id', this.id)
      form.set('description', this.description)

      this.$http.post('first-stage',
          form,
          {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'multipart/form-data',
            }
          })
          .then(({ data }) => {
            if (data.success) {
              this.$router.push({ name: 'userThanks' });
            } else {
              this.error = data.error;
              this.errors = data.errors;
            }
          })
    },
    protectedDate() {
      let now = new Date();
      let start = new Date('2021-05-24');

      if (now.getTime() > start.getTime()) {
        this.$router.push({ name: 'userTasks' });
      }
    },
    getId() {
      this.$http.get('me', {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
        }
      }).then(({ data }) => {
        this.id = data.me.id;

        if (data.me.first_stage) {
          this.$router.push({ name: 'userTasks' });
        }
      })
    }
  }
}
</script>
