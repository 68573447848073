<template>
  <div class="social">
    <a href="https://www.instagram.com/diablochairs_official/?hl=pl" target="_blank">
      <img src="@/assets/img/icons/instagram.svg" alt="instagram"/>
    </a>
    <a href="https://www.facebook.com/diablochairs" target="_blank">
      <img src="@/assets/img/icons/facebook.svg" alt="instagram"/>
    </a>
  </div>
</template>

<script>
export default {
  name: 'Social'
}
</script>
