import { isLoggedIn, logout } from "./jwt";
import { isAdminLoggedIn, adminLogout } from "./admin-jwt";

export const login = {
    computed: {
        loggedIn() {
            return isLoggedIn();
        },
        adminLoggedIn() {
            return isAdminLoggedIn();
        }
    },
    methods: {
        logout() {
            logout();
        },
        adminLogout() {
            adminLogout();
        }
    }
}