<template>
  <div class="sidebar">

    <div class="admin-links" v-if="adminLoggedIn">
      <router-link :to="{ name: 'adminFirstStage' }" class="main-button">Etap 1</router-link>
      <router-link :to="{ name: 'adminSecondStage' }" class="main-button">Etap 2</router-link>
      <router-link :to="{ name: 'adminThirdStage' }" class="main-button">Etap 3</router-link>
      <router-link :to="{ name: 'adminUsers' }" class="main-button">Użytkownicy</router-link>
    </div>

    <router-link :to="{ name: 'start' }" v-if="!loggedIn && !adminLoggedIn" class="main-button">Strona główna</router-link>

    <div class="user-links" v-if="loggedIn">
      <router-link :to="{ name: 'userTasks' }" class="main-button">Moje zadania</router-link>
      <router-link :to="{ name: 'userAccount' }" class="main-button">Moje konto</router-link>
      <router-link :to="{ name: 'userAwards' }" class="main-button">Nagrody</router-link>
    </div>

    <router-link :to="{ name: 'regulation' }" class="main-button">Regulamin</router-link>
    <router-link :to="{ name: 'privacyPolicy' }" class="main-button">Polityka prywatności</router-link>

    <button class="main-button logout" v-if="loggedIn" @click.prevent="logout">
      <img src="@/assets/img/icons/lock.svg" alt="lock"/> Wyloguj się
    </button>
    <button class="main-button logout" v-if="adminLoggedIn" @click.prevent="adminLogout">
      <img src="@/assets/img/icons/lock.svg" alt="lock"/> Wyloguj się
    </button>
  </div>
</template>

<script>
import { login } from "../../utils/mixins";

export default {
  name: 'MenuLinks',
  mixins: [login]
}
</script>