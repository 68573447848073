<template>
  <section class="user-section document">
    <div class="container">
      <div class="row">
        <div class="col-xl-2 col-lg-3 col-md-12">
          <SideBar></SideBar>
        </div>
        <div class="col-xl-8 col-lg-6 col-md-12">
          <div class="document-content">
            <h1><img src="@/assets/img/titles/regulamin.svg" alt="title" class="title-img"/></h1>

            <span class="number-label">§1</span>

            <h3>Organizator Wydarzenia</h3>
            <ol>
              <li>Organizatorem wydarzenia (dalej jako „Konkurs”) i fundatorem nagród jest Domator24.com - Paweł Nowak, ul. Dekoracyjna 8, 65-155 Zielona Góra, NIP 9730551869 , REGON 978094558 (dalej jako „Organizator” lub „Fundator”). Konkurs organizowany jest za pośrednictwem podstrony Organizatora https://challenge.diablochairs.com/ Wydarzenie obejmuje przeprowadzenie konkursu (dalej jako „Konkurs”) za pośrednictwem portalu Instagram @diablochairs_official, Facebook  @diablochairs oraz Youtube.com</li>
              <li>Konkurs nie jest w jakikolwiek sposób powiązany z w/w portalami społecznościowymi, ani z innymi portalami społecznościowymi, ani podmiotami dysponującymi prawami do tych portali, w związku z czym wszelkie roszczenia związane z konkursem winny być kierowane do Organizatora,</li>
              <li>Organizator i Uczestnicy zobowiązani są przestrzegać przy organizacji Konkursu wszelkich regulacji obowiązujących w ramach portali społecznościowych wykorzystywanych w związku z organizacja i uczestnictwem w Konkursie. Naruszenie tych regulacji przez Uczestnika może stanowić naruszenie warunków udziału w Konkursie.</li>
              <li>Konkurs nie jest grą losową, loterią fantową, zakładem wzajemnym, loterią promocyjną, grą, której wynik zależy od przypadku, ani żadną inną formą przewidzianą w ustawie z dnia 19 listopada 2009 r. o grach hazardowych.</li>
            </ol>

            <span class="number-label">§2</span>

            <h3>Czas trwania Konkursu</h3>
            <ol>
              <li>Konkurs trwa od momentu publikacji (udostępnienia) pierwszego zadania konkursowego na podstronie organizatora w dniu 24.05.2021r. i trwa do dnia 20.06.2021r. do godziny 23:59 włącznie. Oznacza to, że w Konkursie biorą udział tylko Ci Uczestnicy, którzy w tym okresie spełnią warunki uczestnictwa opisane poniżej.</li>
              <li>O laureatach Konkursu Organizator poinformuje do dnia 30.06.2021r. włącznie.</li>
              <li>Informacja o laureatach zostanie podana na podstronie Organizatora https://challenge.diablochairs.com/ a także w ramach jego kanałów i profili o jakich mowa w §1 ust 1 .</li>
              <li>Konkurs polega na wykonaniu przez uczestnika łącznie trzech zadań konkursowych zwanych w dalszej części regulaminu: zadanie nr 1, zadanie nr 2, zadanie nr 3 oraz na przesłaniu prac konkursowych dotyczących tych zadań za pośrednictwem podstrony organizatora w terminie zgodnym z § 2.1 Tematy poszczególnych zadań będą ujawniane stopniowo na stronie o jakiej mowa w §2 ust 3, w dniach 24.05.2021 roku, 01.06.2021 roku oraz 09.06.2021 roku. Uczestnik ma prawo przystąpić do konkursu w każdym momencie w ramach przedziału czasowego wskazanego w §2 ust 1. </li>
            </ol>

            <span class="number-label">§3</span>

            <h3>Komisja Konkursowa</h3>
            <p>Celem zapewnienia prawidłowej organizacji i przebiegu Konkursu, a w szczególności w celu dokonania oceny prawidłowości zgłoszeń do Konkursu oraz wyłonienia laureatów Organizator powołał komisję konkursową (dalej jako „Komisja”). W skład Komisji wchodzą cztery osoby:  pracownicy z Działu Marketingu Organizatora. W przypadku braku jednomyślności lub w przypadku równowagi głosów decydujący głos ma przewodniczący komisji.</p>

            <span class="number-label">§4</span>

            <h3></h3>
            <ol>
              <li>W Konkursie mogą brać udział osoby ﬁzyczne, zamieszkałe na terytorium Rzeczypospolitej Polskiej. Jeżeli udział w Konkursie bierze osoba niepełnoletnia powinna posiadać pisemną zgodę swojego przedstawiciela ustawowego na udział w Konkursie. W przypadku wygranej zgodę należy przekazać Organizatorowi. Wzór zgody stanowi załącznik do niniejszego regulaminu.</li>
              <li>Udział w Konkursie i związane z nim udostępnienie danych jest całkowicie dobrowolne. Osoba, która przystąpiła do Konkursu (dalej jako „Uczestnik”) jest związana warunkami Regulaminu.</li>
              <li>Przystąpienie do konkursu odbywa się poprzez:
                <ol class="type">
                  <li>przesłanie w wymaganym terminie zgodnym z § 2.1 do Organizatora Pracy Konkursowej w formacie i tematyce opisanej w ust 4 poniżej, za pośrednictwem konta uczestnika założonego na podstronie Organizatora znajdującej się pod adresem  https://challenge.diablochairs.com/</li>
                  <li>polubienie za pośrednictwem konta Uczestnika w ramach portalu społecznościowego  Instagram profilu   @diablochairs_official oraz  profilu @diablochairs na portalu społecznościowym Facebook,</li>
                </ol>
              </li>
              <li>Praca Konkursowa składa się z trzech zadań (dwóch zadań o charakterze twórczym i jednego quizu) , których szczegóły będą stopniowo ujawniane w trakcie trwania Konkursu, i które powinny spełniać następujące wymogi:
                <ul>
                  <li>zadanie nr 1.   Praca Konkursowa w postaci odpowiedzi tekstowej, która  powinna stanowić przejaw działalności twórczej uczestnika, a jej przesłanie odbywa się pośrednictwem podstrony Organizatora w formacie i rozmiarze nie większym niż pozwala na to podstrona.</li>
                  <li>zadanie nr 2,. Praca Konkursowa  powinna stanowić przejaw działalności twórczej uczestnika w postaci zdjęcia, a jej przesłanie odbywa się pośrednictwem podstrony Organizatora w formacie i rozmiarze nie większym niż pozwala na to podstrona. W zadaniu nr 2 uczestnik ma dodatkowo możliwość publikacji pracy konkursowej(konto publiczne) w portalu społecznościowym Instagram za pośrednictwem swojego profilu wraz z oznaczeniem profilu organizatora @diablochairs_official i hasztagu #diablofamilychallenge. Publikacja pracy konkursowej w portalu społecznościowym Instagram nie zwalania uczestnika z obowiązku przesłania pracy konkursowej z wykorzystaniem podstrony.</li>
                  <li>zadanie nr 3 stanowi quiz i polega na udzieleniu odpowiedzi  na 10 pytań jednokrotnego wyboru spośród czterech podanych możliwości (a,b,c,d). Udzielenie odpowiedzi jest równoznaczne z zaznaczeniem odpowiedniego wariantu odpowiedzi na stronie Organizatora i przesłanie ich organizatorowi z wykorzystaniem opcji dostępnych na koncie uczestnika na konkursowej podstronie Organizatora.</li>
                  <li>kryterium oceny pracy konkursowej zadanie nr 1 oraz zadanie nr 2 jest kreatywność, poczucie humoru, estetyka pracy  a w przypadku zadania nr 3 kryterium oceny pracy konkursowej jest poprawność udzielonych odpowiedzi. Suma punktów zdobytych przez uczestnika konkursu w zadaniu nr 1, zadaniu nr 2 oraz zadaniu nr 3 stanowi podstawę przyznania poszczególnych nagród. Zasady punktacji opisane są w §5 ust  5</li>
                </ul>
              </li>
              <li>W Konkursie nie mogą brać udziału pracownicy, członkowie władz i przedstawiciele Organizatora, a także osoby współpracujące z Organizatorem na innej podstawie niż stosunek pracy oraz członkowie ich najbliższych rodzin, tj. wstępni, zstępni, rodzeństwo, małżonkowie, rodzice małżonków i osoby pozostające z nimi w stosunku przysposobienia.</li>
              <li>Poprzez wysłanie Pracy Konkursowej  Uczestnik wyraża zgodę na zapis Regulaminu oraz przetwarzanie danych osobowych na zasadach opisanych w regulaminie.</li>
            </ol>

            <span class="number-label">§5</span>

            <h3>Zasady i przebieg konkursu</h3>
            <ol>
              <li>Poprzez udział w Konkursie Uczestnik wyraża zgodę na prezentacje prac w ramach kanału Organizatora o jakich mowa w §4 ust 3 lit b) i na podstronie Organizatora https://challenge.diablochairs.com/ Do oznaczenia Uczestnika zostanie użyte oznaczanie - nick pod jakim zarejestrowane jest konto na podstronie Organizatora, z którego nadesłano Prace Konkursowe</li>
              <li>Organizator ma prawo odrzucenia Pracy Konkursowej i/lub pozbawienia prawa uczestnictwa w Konkursie Uczestnika w przypadku:
                <ul>
                  <li>naruszania niniejszego regulaminu,</li>
                  <li>naruszani przepisów powszechnie obowiązującego prawa, w szczególności poprzez wykorzystywanie konkursu do popełnienia przestępstwa lub wykroczenia,</li>
                  <li>naruszania dóbr  osobistych osób trzecich, np. poprzez ich szykanowanie, przedstawiane w negatywnym świetle itp,</li>
                  <li>naruszające prawa autorskie lub prawa pokrewne osób trzecich,</li>
                  <li>stanowiące przejaw jakiejkolwiek dyskryminacji.</li>
                </ul>
              </li>
              <li>Decyzje o jakiej mowa w ust 2 podejmuje Komisja, której decyzja jest ostateczna.</li>
              <li>W terminie wskazanym w §2 Komisja dokonuje wyboru najlepszych prac, które zwyciężyły w Konkursie.  Komisja bierze pod uwagę przede wszystkim zgodność Pracy Konkursowej z tematem Konkursu, jakość i technikę wykonania, pomysłowość, poczucie humoru oraz nawiązanie do marki Diablo Chairs a w przypadku zadania nr 3 poprawność udzielonych odpowiedzi.</li>
              <li>Punktacja dotycząca poszczególnych zadań konkursowych:
                <ol class="type">
                  <li>Zadanie nr 1 – uczestnik może uzyskać maksymalnie 10 pkt podstawowych(10 diabełków)  plus 1 EXTRA DIABEŁEK, który przyznaje przewodniczący komisji np. za wyjątkową kreatywność</li>
                  <li>Zadanie nr 2 - uczestnik może uzyskać maksymalnie 10 pkt podstawowych (10 diabełków)   plus 1 EXTRA DIABEŁEK za publikacje pracy konkursowej na IG wraz z wymaganymi oznaczeniami.</li>
                  <li>Zadanie nr  3 - 10 pkt (10 diabełków) po 1 pk (1 diabełku) za każdą poprawną odpowiedź</li>
                </ol>
              </li>
              <li>Punkty uzyskane przez uczestnika konkursu Diablo Familly Challenge będą widoczne po zalogowaniu się uczestnika na jego konto na podstronie Organizatora –  w dniu 23.06.2021r.</li>
            </ol>

            <span class="number-label">§6</span>

            <h3>Nagrody</h3>
            <ol>
              <li>Organizator przewiduje następujące nagrody:
                <ol class="type">
                  <li>za zajęcie I miejsca: 1 x Fotel Gamingowy Diablo Chairs z serii X, 1 x mata antypoślizgowa, 4 x koszulki (kolor i rozmiar do wyboru z oferty dostępnej na stronie https://diablochairs.com, w dniu ogłoszenia laureatów konkursu)</li>
                  <li>za zajęcie II miejsca: 1 x Fotel Gamingowy Diablo Chairs z serii X ,  1 x podkladka LED, 4 x koszulki (kolor i rozmiar do wyboru z oferty dostępnej na stronie https://diablochairs.com, w dniu ogłoszenia laureatów konkursu) (kolor i rozmiar do wyboru z oferty dostępnej na stronie https://diablochairs.com, w dniu ogłoszenia laureatów konkursu)</li>
                  <li>za zajęcie III miejsca: 1 x Biurko Gamingowe Diablo Chairs, 1 x worko-plecak 4 x koszulki (kolor i rozmiar do wyboru z oferty dostępnej na stronie https://diablochairs.com, w dniu ogłoszenia laureatów konkursu)</li>
                  <li>3 x NAGRODY POCIESZENIA w formie ZESTAWU GADŻETÓW dla trzech różnych uczestników: w skład jednego zestawu gadżetów wchodzą: 1 x  kubek,  1 x podkładka pod mysz, 1 x smycz, 1 x opaska, 1 x długopis. Nagrody za te Prace Konkursowe  przyznawane są bez względu na uzyskaną przez nie liczbę punktów.  </li>
                </ol>
                <p>Organizator  zastrzega sobie prawo do przyznania dodatkowych nagród rzeczowych dla laureatów.</p>
              </li>
              <li>Do wartości nagrody zostanie dodana dodatkowa nagroda pieniężna w wysokości odpowiadającej zryczałtowanemu podatkowi dochodowemu od osób ﬁzycznych z tytułu wygranej w Konkursie, w kwocie stanowiącej 11,11% wartości Nagrody. Laureat zgadza się, że kwota dodatkowej nagrody pieniężnej nie podlega wypłacie na jego rzecz, lecz przeznaczona jest na zapłatę podatku należnego z tytułu wygranej Nagrody w Konkursie.</li>
              <li>Warunkiem wydania Nagrody jest przesłanie Organizatorowi przez Uczestnika z konta z jakiego nastąpiło zgłoszenie Pracy Konkursowej, danych osobowych niezbędny do wypełnienia obowiązku podatkowego o jakim mowa w ust 2. Organizator, jako płatnik zryczałtowanego podatku dochodowego od osób ﬁzycznych, przed wydaniem laureatowi Nagrody obliczy, pobierze i odprowadzi do właściwego Urzędu Skarbowego zryczałtowany podatek dochodowy należny z tytułu wygranej.</li>
              <li>Nagrody zostaną przesłane do laureatów po przesłaniu przez nich Organizatorowi z adresu e-mail na jaki zostało zarejestrowane konto uczestnika na podstronie tj. z jakiego nastąpiło zgłoszenie Pracy Konkursowej adresu na jaki nagroda ma być dostarczona. Adres musi znajdować się w Polsce.  </li>
              <li>Przekazanie nagrody, o której mowa w ust. 1, nastąpi za pośrednictwem Poczty Polskiej lub wybranego przez Organizatora Kuriera; wydanie nagród Poczcie Polskiej lub Kurierowi nastąpi w terminie 7 dni od dnia otrzymania danych o jakich mowa w ust 4.</li>
              <li>Organizatorowi przysługuje prawo weryﬁkacji danych osobowych laureata w momencie wydania Nagrody, w celu identyﬁkacji osoby nagrodzonej, a także potwierdzenia jej pełnoletności, a w przypadku osoby niepełnoletniej – prawo do weryfikacji zgody na udział w konkursie opiekuna prawnego niepełnoletniego uczestnika. Przed wydaniem Nagrody laureatowi może zostać zobligowany do okazania stosownego dokumentu.</li>
            </ol>

            <span class="number-label">§7</span>

            <h3>Prawa własności intelektualnej do Prac Konkursowych</h3>
            <ol>
              <li>Biorąc udział w Konkursie, Uczestnik jednocześnie oświadcza, że jest twórcą Pracy Konkursowej, w rozumieniu ustawy z dnia 4 lutego 1994 r. o prawie autorskim i prawach pokrewnych oraz, że przysługują mu pełne autorskie prawa majątkowe do Pracy Konkursowej wraz z ewentualnymi zezwoleniami na rozpowszechnianie utrwalonych na zgłaszanej Pracy Konkursowej wizerunków Uczestnika oraz osób trzecich.</li>
              <li>W przypadku gdy Praca Konkursowa polega na utrwaleniu za pomocą fotografii (lub innej techniki rejestrującej obraz i/lub dźwięk) utworu Uczestnika (np. rzeźby, wypowiedzi) za Prace Konkursową, której dotyczy przeniesienie praw  o jakich mowa poniżej,  uważa się to utrwalenie.</li>
              <li>Organizator z chwilą wydania Nagrody nabywa pełne, w tym autorskie prawa majątkowe i prawa zależne do Prac Konkursowych, stanowiących utwór, na wszystkich polach eksploatacji znanych w chwili wydania Nagrody, w tym wymienionych w art. 50 ustawy o prawie autorskim i prawach pokrewnych, bez jakichkolwiek ograniczeń terytorialnych czy też czasowych, co w szczególności obejmuje następujące pola eksploatacji:
                <ol class="type">
                  <li>w zakresie utrwalania i zwielokrotniania utworu oraz jego opracowań – wytwarzanie dowolną techniką, w tym techniką drukarską, reprograﬁczną, zapisu magnetycznego oraz techniką cyfrową,</li>
                  <li>w zakresie obrotu oryginałami utworu oraz jego opracowaniami albo egzemplarzami, na których utwór utrwalono – wprowadzania do obrotu, użyczania lub najmu oryginału albo egzemplarzy,</li>
                  <li>w zakresie rozpowszechniania utworu w - publiczne wykonanie, wystawienie, wyświetlenie, odtworzenie oraz nadawanie i reemitowanie, a także publiczne udostępnianie utworu w taki sposób, aby każdy mógł mieć do niego dostęp w miejscu i w czasie przez siebie wybranym, w szczególności wykorzystanie we wszelkich formach komunikacji, promocji lub reklamy, w szczególności w charakterze materiału reklamowego lub promocyjnego, w charakterze elementu materiałów reklamowych lub promocyjnych takich jak ogłoszenia prasowe, reklama poprzez portale społecznościowe (np. Facebook, Instagram), reklamy zewnętrzne, ﬁlmy reklamowe, rozpowszechnianie w sieci Internet.</li>
                </ol>
              </li>
              <li>Uczestnik  upoważnia Organizatora do realizowania przysługujących mu osobistych autorskich praw w zakresie anonimowego rozpowszechniania nagrodzonej Pracy Konkursowej, w zakresie decydowania o jej pierwszym publicznym udostępnieniu oraz sprawowania nadzoru nad sposobem korzystania z Pracy Konkursowej i jednocześnie zrzeka się prawa do realizacji w/w praw.</li>
              <li>Organizator nabywa prawo  do wykonywania praw zależnych do Utworów oraz do zezwalania innym osobom na wykonywanie praw zależnych do Utworów.</li>
              <li>Uczestnik wyraża niniejszym bezterminową i nieodwołalną zgodę na dokonywanie naruszenia integralności treści i formy Utworów oraz swobodne ich wykorzystanie przez Organizatora.</li>
              <li>W razie konieczności, laureat na żądanie Organizatora podpisze stosowne oświadczenia lub umowy potwierdzające
                <ol>
                  <li>przeniesienie praw autorskich do nagrodzonych Prac Konkursowych lub</li>
                  <li>możliwość korzystania z utrwalonego na Pracy Konkursowej wizerunku osób ﬁzycznych.</li>
                </ol>
              </li>
              <li>Uczestnik ponosi pełną odpowiedzialność względem Organizatora w przypadku zgłoszenia przez osoby trzecie roszczeń lub pociągnięcia Organizatora do odpowiedzialności w związku z wykorzystaniem przez Organizatora Prac Konkursowych, do których prawa nabył na mocy Regulaminu.</li>
              <li>Z uwagi na to, że rezultatem udziału w Konkursie może być przyznanie Uczestnikowi Nagrody na zasadach opisanych w Regulaminie, z tytułu przeniesienia praw nie przysługuje dodatkowe wynagrodzenie.</li>
            </ol>

            <span class="number-label">§8</span>

            <h3>Przetwarzanie danych osobowych</h3>
            <ol>
              <li>Zgłaszając udział w Konkursie Uczestnik, będący osobą ﬁzyczną, wyraża zgodę na przetwarzanie podanych przez niego danych osobowych przez Organizatora jako administratora, oraz Prowadzącego, który występuje w roli podmiotu przetwarzającego o jakim mowa w art. 28 Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych) dalej „RODO”.  Za dane Uczestnika rozumie się także dane jego przedstawiciela ustawowego. Administrator powołał inspektora ochrony danych osobowych, z którym skontaktować się można pod adresem e-mail: iodo@domator24.com</li>
              <li>Podanie danych osobowych w postaci adresu poczty elektronicznej czy oznaczenia konta z jakiego praca jest wysyłana jest dobrowolne, ale niezbędne do uczestnictwa w Konkursie. Podanie danych niezbędnych do uiszczenia podatku oraz wysyłki Nagrody, a także ustalenia pełnoletności oraz dane przedstawiciela ustawowego   jest dobrowolnemu ale niezbędne do realizacji tych czynności. Wszelka komunikacja odbywać się będzie przede wszystkim za pośrednictwem adresu poczty elektronicznej podanym dla oznaczenia konta.</li>
              <li>Organizator, jako administrator danych osobowych, informuje, że dane osobowe Uczestnika będą przetwarzane:
                <ol class="type">
                  <li>w celu związanym z organizacją i realizacją Konkursu, w tym  przeniesienia  praw autorskich na podstawie art. 6 ust. 1 lit. a), b) i c) RODO;</li>
                  <li>w celu wykonywania obowiązków podatkowych, na podstawie art. 6 ust. 1 lit. c) i f) RODO;</li>
                </ol>
              </li>
              <li>Dane osobowe Uczestników Konkursów będą przetwarzane przez Organizatora przez czas niezbędny dla realizacji celów, w związku z którymi zostały pozyskane t.j. dane Uczestników do czasu zakończenia i rozstrzygnięcia Konkursu oraz przez okres wygaśnięcia ewentualnych roszczeń Uczestników, związanych z Konkursem, dane laureata do czasu wygaśnięcia ewentualnych ich roszczeń oraz dla celów sprawozdawczości wymaganej przepisami prawa;  rozpoznanie reklamacji przez okres wygaśnięcia ewentualnych roszczeń osoby zgłaszającej reklamację; realizacja uprawnień prawno-autorskich wskazanych przez okres posiadania takich uprawnień, realizacji obowiązków podatkowych – przez okres przedawnienia tych obowiązków.</li>
              <li>Uczestnikom Konkursu przysługuje prawo żądania dostępu do ich danych osobowych, sprostowania, usunięcia lub ograniczenia przetwarzania tych danych oraz prawo do wniesienia sprzeciwu wobec przetwarzania, a także prawo do przenoszenia danych, w zakresie przewidzianym prawem.</li>
              <li>Uczestnikom Konkursu przysługuje prawo do cofnięcia wyrażonej zgody na przetwarzanie ich danych osobowych w dowolnym momencie, co jednak nie wpływa na zgodność z prawem przetwarzania, którego dokonano na podstawie wyrażonej zgody przed jej cofnięciem – w tym celu Uczestnicy Konkursu wysłać odpowiednią informację na adres: marketing@diablochairs.com lub Domator24 | Diablo Chairs, ul. Dekoracyjna 8, 65-155 Zielona Góra. W wyniku cofnięcia zgody Uczestnik jest pozbawiony prawa uczestnictwa w Konkursie.</li>
              <li>Uczestnikom Konkursu przysługuje prawo do złożenia skargi do organu nadzorczego – Prezesa Urzędu Ochrony Danych Osobowych.</li>
              <li>Decyzje dotyczące przebiegu Konkursu nie będą podejmowane w sposób zautomatyzowany i nie będą podlegać proﬁlowaniu.</li>
              <li>Odbiorcami danych osobowych mogą być, oprócz Prowadzącego: pracownicy lub współpracownicy Organizatora, jak również podmioty świadczące na rzecz Organizatora lub Współorganizatora Konkursu, usługi pocztowe, kurierskie, bankowe, doradcze, ﬁnansowe, rachunkowe, podatkowe, audytorskie, prawne lub zajmujące się dostarczaniem usług hostingowych lub usług związanych z systemami i oprogramowaniem IT.</li>
            </ol>

            <span class="number-label">§9</span>
            <h3>Postępowanie reklamacyjne</h3>
            <ol>
              <li>Prawo do złożenia reklamacji przysługuje Uczestnikom. Podstawę reklamacji stanowi naruszenie Regulaminu lub obowiązujących przepisów prawa.</li>
              <li>Zgłoszenie reklamacyjne winno być złożone pocztą elektroniczną na adres Organizatora ⦁	marketing@diablochairs.com lub dostarczone na piśmie na adres Organizatora Domator24.com - Paweł Nowak, ul. Dekoracyjna 8, 65-155 Zielona Góra, NIP 9730551869, REGON 978094558 i powinno zawierać: dokładne dane zgłaszającego reklamację, jego adres, datę i miejsce zdarzenia, adres uczestnika, rodzaj konkursu, treść żądania.</li>
              <li>Reklamacje będą rozpatrywane przez Organizatora.</li>
              <li>Uczestnik wnoszący reklamację zostanie powiadomiony o rozstrzygnięciu za pomocą listu poleconego lub pocztą elektroniczną na adres wskazany przez Uczestnika, nadanego najpóźniej w terminie 14 dni od daty otrzymania reklamacji przez Organizatora.</li>
            </ol>

            <span class="number-label">§10</span>
            <h3>Postanowienia końcowe</h3>
            <ol>
              <li>Konkurs podlega prawu Polskiemu.</li>
              <li>Wszelkie informacje o Konkursie zawarte w jakichkolwiek materiałach promocyjnych i reklamowych mają jedynie charakter pomocniczy. Informacjami wiążącymi są postanowienia niniejszego Regulaminu.</li>
              <li>Regulamin jest dostępny przez Czas Trwania Konkursu pod udostępnionym przez Prowadzącego linkiem oraz w siedzibie Organizatora, a także na stronie https://challenge.diablochairs.com/</li>
              <li>Wszelkie informacje na temat Konkursu można uzyskać kontaktując się z Organizatorem pod adresem e-mail: marketing@diablochairs.com , lub poprzez fanpage Organizatora w ramach Facebooka.</li>
              <li>Organizator zastrzega sobie prawo zmiany Regulaminu i warunków Konkursu, jeżeli jest to uzasadnione celem Konkursu i nie wpłynie na pogorszenie warunków uczestnictwa w Konkursie, a ponadto w sytuacji gdy zmiana jest konieczna w związku z okolicznościami niezależnymi od Organizatora takimi jak zmiana przepisów, zawieszenie/likwidacja kont w portalach społecznościowych używanych do przeprowadzenia Konkursu itp. Regulamin w zmienionej wersji obowiązuje Uczestników biorących udział w Konkursie po wprowadzeniu zmian, chyba, że dotyczy to zmian które z mocy prawa dotyczą wszystkich Uczestników. O zmianach Regulaminu Uczestnicy zostaną powiadomieni poprzez przesłanie wiadomości na konta lub e-maile podane przy zgłoszeniu.  </li>
            </ol>

            <div class="document-buttons">
              <router-link :to="{ name: 'userStart' }" class="main-button">Powrót</router-link>
              <a href="documents/regulamin.pdf" download class="main-button download">Pobierz</a>
            </div>

          </div>
        </div>
        <div class="offset-xl-2 offset-lg-3 d-md-none"></div>
      </div>
    </div>
  </section>
</template>

<script>
import SideBar from "../Partials/SideBar";

export default {
  name: 'Regulation',
  components: {
    SideBar
  },
  data() {
    return {
      regulationLink: '@/assets/documents/regulamin.pdf'
    }
  }
}
</script>
