<template>
  <section class="user-section user-account">
    <div class="container">
      <div class="row">
        <div class="col-xl-2 col-lg-3 col-md-12">
          <SideBar></SideBar>
        </div>
        <div class="col-xl-8 col-lg-6 col-md-12">
          <h1><img src="@/assets/img/titles/moje_konto.svg" alt="title" class="title-img"/></h1>

          <div class="user-account-content">

            <div class="row">
              <div class="col-sm-6 col-12">
                <h3>Imię: <span>{{ user.firstname }}</span></h3>
                <h3>Nazwisko: <span>{{ user.lastname }}</span></h3>
                <h3>Login: <span>{{ user.login }}</span></h3>
              </div>
              <div class="col-sm-6 col-12">
                <h3>Adres e-mail: <span>{{ user.email }}</span></h3>
                <h3>Numer telefonu:  <span>{{ user.phone }}</span></h3>
                <h3>Nick: <span>{{ user.nick }}</span></h3>
              </div>
            </div>

            <div class="divid"></div>

            <div class="account-buttons">
              <button type="button" class="main-button" @click="showEditDataModal">Zmień dane</button>
              <button type="button" class="main-button" @click="showChangePasswordModal">Zmień hasło</button>
            </div>

            <div class="divid"></div>

            <router-link :to="{ name: 'userStart' }" class="main-button back">Powrót</router-link>

            <vue-modal name="editDataModal">
              <h2 slot="header"><img src="@/assets/img/titles/zmien_swoje_dane.svg" alt="title" class="title-img"/></h2>

              <div class="errors">
                <p v-if="error" class="error-label">{{ error }}</p>
                <ul class="errors-list">
                  <li class="error-label" v-for="(err, index) in errors" :key="index">{{ err[0] }}</li>
                </ul>
              </div>

              <form @submit.prevent="editData">
                <div class="row">
                  <div class="col-lg-6 col-md-12">
                    <label for="firstname">Imię</label>
                    <input type="text" id="firstname" v-model="user.firstname"/>

                    <label for="email">Adres e-mail</label>
                    <input type="email" id="email" v-model="user.email"/>

                    <label for="login">Login</label>
                    <input type="text" id="login" v-model="user.login"/>
                  </div>
                  <div class="col-lg-6 col-md-12">
                    <label for="lastname">Nazwisko</label>
                    <input type="text" id="lastname" v-model="user.lastname"/>

                    <label for="phone">Numer telefonu</label>
                    <input type="text" id="phone" v-model="user.phone"/>

                    <label for="nick">Nick</label>
                    <input type="text" id="nick" v-model="user.nick"/>
                  </div>
                </div>

                <div class="modal-buttons">
                  <button type="button" class="main-button" @click="closeEditDataModal">Powrót</button>
                  <button type="submit" class="main-button accept">Zapisz</button>
                </div>
              </form>
            </vue-modal>

            <ChangePassword></ChangePassword>

          </div>
        </div>
        <div class="offset-xl-2 offset-lg-3 d-md-none"></div>
      </div>
    </div>
  </section>
</template>

<script>
import SideBar from "../Partials/SideBar";
import ChangePassword from "../Modals/ChangePassword";
import { User } from "../../models/models";

export default {
  name: 'UserAccount',
  components: {
    ChangePassword,
    SideBar
  },
  data() {
    return {
      error: '',
      errors: [],
      user: User
    }
  },
  mounted: function () {
    this.getMe();
  },
  methods: {
    showEditDataModal() {
      this.$modals.show("editDataModal");
    },
    closeEditDataModal() {
      this.$modals.hide("editDataModal");
    },
    showChangePasswordModal() {
      this.$modals.show("changePasswordModal");
    },
    getMe() {
      this.$http.get('me', {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
        }
      }).then(({ data }) => {
        this.user.id = data.me.id;
        this.user.firstname = data.me.firstname;
        this.user.lastname = data.me.lastname;
        this.user.login = data.me.login;
        this.user.nick = data.me.nick;
        this.user.email = data.me.email;
        this.user.phone = data.me.phone;
      })
    },
    editData() {
      const form = new FormData()

      form.set('login', this.user.login)
      form.set('nick', this.user.nick)
      form.set('firstname', this.user.firstname)
      form.set('lastname', this.user.lastname)
      form.set('email', this.user.email)
      form.set('phone', this.user.phone)

      this.$http.post('edit',
          form,
          {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'multipart/form-data',
            }
          })
          .then(({ data }) => {
            if (data.success) {
              this.closeEditDataModal();
              this.getMe();
            } else {
              this.error = data.error;
              this.errors = data.errors;
            }
          })
    }
  }
}
</script>
