<template>
  <div class="login-content">
    <img src="@/assets/img/icons/devil-logo.svg" class="devil-logo" alt="diablochairs-logo"/>
    <p class="desc" v-if="!isAdminForm">Aby wziąć udział i wykonać zadania konkursowe, zaloguj się do swojego konta.</p>

    <div class="errors">
      <p v-if="error" class="error-label">{{ error }}</p>
      <ul class="errors-list">
        <li class="error-label" v-for="(err, index) in errors" :key="index">{{ err[0] }}</li>
      </ul>
    </div>

    <form @submit.prevent="userLogin" v-if="!isAdminForm">
      <label for="email">Adres e-mail</label>
      <input type="email" name="login" id="login" v-model="email"/>

      <label for="password">Hasło</label>
      <input type="password" name="password" id="password" v-model="password"/>

      <!--
      <p v-if="!isAdminForm" class="link">Nie posiadasz konta? <router-link :to="{ name: 'register' }">Zarejestruj się</router-link></p>
      -->

      <button type="submit" name="submit" class="main-button">Zaloguj się</button>
    </form>

    <form @submit.prevent="adminLogin" v-if="isAdminForm">
      <label for="admin-login">Login</label>
      <input type="text" name="login" id="admin-login" v-model="loginAdmin"/>

      <label for="admin-password">Hasło</label>
      <input type="password" name="password" id="admin-password" v-model="passwordAdmin"/>

      <button type="submit" name="submit" class="main-button">Zaloguj się</button>
    </form>

  </div>
</template>

<script>
  import { login } from '../../utils/jwt'
  import { adminLogin } from '../../utils/admin-jwt';

  export default {
    name: 'LoginForm',
    computed: {
      isAdminForm() {
        return this.$route.name === 'adminLogin'
      }
    },
    data() {
      return {
        email: '',
        password: '',
        loginAdmin: '',
        passwordAdmin: '',
        error: '',
        errors: []
      }
    },
    methods: {
      userLogin () {
        const form = new FormData()

        form.set('email', this.email)
        form.set('password', this.password)

        this.$http.post('login',
            form,
            {
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data',
              }
            })
            .then(({ data }) => {
              if (data.token) {
                login(data.token)
              } else {
                this.error = data.error;
                this.errors = data.errors;
              }
            })
      },
      adminLogin () {
        const form = new FormData()

        form.set('login', this.loginAdmin)
        form.set('password', this.passwordAdmin)

        this.$http.post('admin',
            form,
            {
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data',
              }
            })
            .then(({ data }) => {
              if (data.token) {
                adminLogin(data.token)
              } else {
                this.error = data.error;
                this.errors = data.errors;
              }
            })
      }
    }
  }
</script>