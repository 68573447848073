<template>
  <section class="admin-start">
    <div class="container">
      <div class="row">
        <div class="col-xl-3 col-lg-6 col-sm-12">
          <router-link :to="{ name: 'adminUsers' }">
            <div class="box">
              <img src="@/assets/img/titles/uzytkownicy.svg" alt="title" class="title-img"/>
              <span>({{ users }})</span>
            </div>
          </router-link>
        </div>
        <div class="col-xl-3 col-lg-6 col-sm-12">
          <router-link :to="{ name: 'adminFirstStage' }">
            <div class="box">
              <img src="@/assets/img/titles/pierwszy_etap.svg" alt="title" class="title-img"/>
              <span>({{ first_stages }})</span>
            </div>
          </router-link>
        </div>
        <div class="col-xl-3 col-lg-6 col-sm-12">
          <router-link :to="{ name: 'adminSecondStage' }">
            <div class="box">
              <img src="@/assets/img/titles/drugi_etap.svg" alt="title" class="title-img"/>
              <span>({{ second_stages }})</span>
            </div>
          </router-link>
        </div>
        <div class="col-xl-3 col-lg-6 col-sm-12">
          <router-link :to="{ name: 'adminThirdStage' }">
            <div class="box">
              <img src="@/assets/img/titles/trzeci_etap.svg" alt="title" class="title-img"/>
              <span>({{ third_stages }})</span>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AdminStart',
  data() {
    return {
      users: 0,
      first_stages: 0,
      second_stages: 0,
      third_stages: 0
    }
  },
  mounted: function () {
    this.getTotals();
  },
  methods: {
    getTotals() {
      this.$http.get('totals', {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
        }
      })
          .then(({ data }) => {
            this.users = data.users;
            this.first_stages = data.first_stages;
            this.second_stages = data.second_stages;
            this.third_stages = data.third_stages;
          })
    },
  }
}
</script>
