<template>
  <section class="register-section">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
         <div class="register-content">
           <p class="desc">Stwórz swoje konto i dołącz do naszej zabawy.</p>

           <div class="errors">
             <p v-if="error" class="error-label">{{ error }}</p>
             <ul class="errors-list">
               <li class="error-label" v-for="(err, index) in errors" :key="index">{{ err[0] }}</li>
             </ul>
           </div>

           <form @submit.prevent="userRegister">
             <div class="row">
               <div class="col-lg-6 col-md-12">
                 <label for="login">Login</label>
                 <input type="text" id="login" v-model="login"/>

                 <label for="firstname">Imię</label>
                 <input type="text" id="firstname" v-model="firstname"/>

                 <label for="email">Adres e-mail</label>
                 <input type="email" id="email" v-model="email"/>
               </div>
               <div class="col-lg-6 col-md-12">
                 <label for="password">Hasło</label>
                 <input type="password" id="password" v-model="password"/>

                 <label for="lastname">Nazwisko</label>
                 <input type="text" id="lastname" v-model="lastname"/>

                 <label for="phone">Numer telefonu</label>
                 <input type="text" id="phone" v-model="phone"/>
               </div>

               <div class="col-lg-6 col-md-12">
                 <label for="nick">Nick</label>
                 <input type="text" id="nick" v-model="nick"/>
               </div>
               <div class="col-lg-6 col-md-12">
                 <div class="nick-info-content">
                   <p class="nick-info"><span>*</span>Twój "Nick" zostanie wykorzystany podczas prezentacji nagrodzonej/wyróżnionej pracy konkursowej.</p>
                 </div>
               </div>

               <div class="col-lg-6 col-md-12">
                 <p-check color="success" class="p-default checkbox-content" v-model="agree">
                   Zapoznałem się z regulaminem Diablo Family Challenge i akceptuję <router-link :to="{ name: 'regulation' }">regulamin konkursu</router-link>
                 </p-check>
               </div>
               <div class="col-lg-6 col-md-12">
                 <p-check color="success" class="p-default checkbox-content" v-model="newsletter">
                   Wyrażam zgodę na otrzymywanie wiadomości drogą elektroniczną (newsletter) od Diablo Chairs zawierającego informacje o produktach, premierach i promocjach na mój adres e-mail podany podczas rejestracji.
                 </p-check>
               </div>
               <div class="col-lg-12">
                 <p-check color="success" class="p-default checkbox-content" v-model="marketing" requried>
                   Wyrażam zgodę na przetwarzanie moich danych osobowych podanych w celach marketingowych przez sklep Diablo Chairs.
                 </p-check>
                 <p>Administratorem danych osobowych w powyższym formularzu jest Domator24.com Paweł Nowak, z siedzibą przy ul. Dekoracyjnej 8, 65-155 Zielona Góra. Podanie danych jest dobrowolne, jednak jeśli ich nie podasz, złożenie zamówienia będzie niemożliwe. Szczegółowe informacje na temat przetwarzania Twoich danych osobowych przez administratora i twoich uprawnieniach znajdziesz <a href="https://diablochairs.com/polityka-prywatnosci" target="_blank">tutaj</a></p>
               </div>

               <div class="col-lg-12">
                 <button type="submit" name="submit" class="main-button">Zarejestruj się</button>
               </div>
             </div>
           </form>
         </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {login} from "../../utils/jwt";

export default {
  name: 'Register',
  data() {
    return {
      login: '',
      nick: '',
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      password: '',
      agree: 0,
      marketing: 0,
      newsletter: 0,
      error: '',
      errors: []
    }
  },
  mounted() {
    this.protectedDate();
  },
  methods: {
    protectedDate() {
      let now = new Date();
      let start = new Date('2021-05-31');

      if (now.getTime() > start.getTime()) {
        this.$router.push({ name: 'start' });
      }
    },
    userLogin () {
      const form = new FormData()

      form.set('email', this.email)
      form.set('password', this.password)

      this.$http.post('login',
          form,
          {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'multipart/form-data',
            }
          })
          .then(({ data }) => {
            if (data.token) {
              login(data.token)
            }
          })
    },
    userRegister() {
      const form = new FormData()

      form.set('login', this.login)
      form.set('password', this.password)
      form.set('nick', this.nick)
      form.set('firstname', this.firstname)
      form.set('lastname', this.lastname)
      form.set('email', this.email)
      form.set('phone', this.phone)
      form.set('agree', this.agree)
      form.set('marketing', this.marketing)
      form.set('newsletter', this.newsletter)

      this.$http.post('register',
          form,
          {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'multipart/form-data',
            }
          })
          .then(({ data }) => {
            if (data.success) {
              this.userLogin();
            } else {
              this.error = data.error;
              this.errors = data.errors;
            }
          })
    }
  }
}
</script>
