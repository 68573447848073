import router from "../router";

export function getToken() {
    return window.localStorage.getItem("token");
}

export function login(token) {
    window.localStorage.clear();
    window.localStorage.setItem("token", token);
    router.push({ name: 'userStart' });
}

export function logout() {
    window.localStorage.clear();
    router.push({ name: 'start' });
}

export function isLoggedIn() {
    return getToken();
}