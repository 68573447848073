<template>
  <section class="user-section user-thanks">
    <div class="container">
      <div class="row">
        <div class="col-xl-2 col-lg-3 col-md-12">
          <SideBar></SideBar>
        </div>
        <div class="col-xl-8 col-lg-6 col-md-12">
          <div class="user-thanks-content">
            <h1><img src="@/assets/img/titles/dziekujemy.svg" alt="title" class="title-img"/></h1>
            <p class="desc">Twoja odpowiedź została zapisana, już wkrótce ocenimy ją i przyznamy Ci punkty w postaci określonej liczby diabełków. Wyczekuj kolejnego zadania!</p>

            <router-link :to="{ name: 'userTasks' }" class="main-button back">Moje zadania</router-link>
          </div>
        </div>
        <div class="offset-xl-2 offset-lg-3 d-md-none"></div>
      </div>
    </div>
  </section>
</template>

<script>
import SideBar from "../Partials/SideBar";

export default {
  name: 'userThanks',
  components: {
    SideBar
  }
}
</script>