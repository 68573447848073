<template>
  <section class="user-section user-awards">
    <div class="container">
      <div class="row">
        <div class="col-xl-2 col-lg-3 col-md-12">
          <SideBar></SideBar>
        </div>
        <div class="col-xl-8 col-lg-6 col-md-12">
          <div class="user-awards-content">
            <h1><img src="@/assets/img/titles/nagrody.svg" alt="title" class="title-img"/></h1>

            <div class="row">
              <div class="col-xl-4 col-lg-12">
                <div class="award-box">
                  <div class="content">
                    <div class="center">
                      <h2>I miejsce</h2>
                      <p>Fotel Gamingowy Diablo Chairs z serii X, mata antypoślizgowa, 4 x koszulki z logo Diablo Chairs.</p>
                    </div>
                  </div>
                  <img src="@/assets/img/images/large-award1.png" alt="award" class="award-img"/>
                </div>

                <a href="https://diablochairs.com/fotele-gamingowe" class="main-button go-to-shop" target="_blank">Zobacz</a>
              </div>
              <div class="col-xl-4 col-lg-12">
                <div class="award-box">
                  <div class="content">
                    <div class="center">
                      <h2>II miejsce</h2>
                      <p>Fotel Gamingowy Diablo Chairs z serii X , podkładka gamingowa LED, 4 x koszulki z logo Diablo Chairs.</p>
                    </div>
                  </div>
                  <img src="@/assets/img/images/large-award3.png" alt="award" class="award-img"/>
                </div>

                <a href="https://diablochairs.com/akcesoria" class="main-button go-to-shop" target="_blank">Zobacz</a>
              </div>
              <div class="col-xl-4 col-lg-12">
                <div class="award-box">
                  <div class="content">
                    <div class="center">
                      <h2>III miejsce</h2>
                      <p>Biurko Gamingowe Diablo Chairs X - Mate 1400, worko-plecak, 4 x koszulki z logo Diablo Chairs.</p>
                    </div>
                  </div>
                  <img src="@/assets/img/images/large-award2.png" alt="award" class="award-img"/>
                </div>

                <a href="https://diablochairs.com/3713-biurko-gamingowe-diablo-x-mate-1400" class="main-button go-to-shop" target="_blank">Zobacz</a>
              </div>
            </div>
          </div>
        </div>
        <div class="offset-xl-2 offset-lg-3 d-md-none"></div>
      </div>
    </div>
  </section>
</template>

<script>
import SideBar from "../Partials/SideBar";

export default {
  name: 'UserAwards',
  components: {
    SideBar
  }
}
</script>
