<template>
  <section class="admin-second-stage">
    <div class="container">
      <div class="row">
        <div class="col-xl-2 col-lg-3 col-md-12">
          <SideBar></SideBar>
        </div>
        <div class="col-xl-10 col-lg-9 col-md-12">
          <h1><img src="@/assets/img/titles/odpowiedzi_do_etapu2.svg" alt="title" class="title-img"/></h1>

          <div class="empty-block" v-if="!secondStages.length">
            <p>Brak danych odnośnie tego etapu.</p>
          </div>

          <div class="row" v-if="secondStages.length">
            <div class="col-lg-4 col-md-6 col-sm-12" v-for="(user, index) in secondStages" :key="index">
              <div class="question-box">
                <h3>Użytkownik: <router-link :to="{ name: 'adminUser', params: { 'id': user.id } }">{{ user.nick }}</router-link></h3>
                <img :src="'https://challenge-api.fkowalik.pl/' + user.second_stage.image" alt="instagram" class="user-img"/>
                <p class="link" v-if="user.second_stage.link">Instagram: <a :href="user.second_stage.link" target="_blank" class="instagram-link" content="Otwórz link w nowym oknie." v-tippy="{ placement : 'top',  arrow: true }">Otwórz link</a></p>
                <p class="link" v-if="!user.second_stage.link">Instagram: <span>Brak linku</span></p>
                <p class="points" v-if="user.second_stage.points === 0">
                  <span>Punkty:</span>
                  <button @click="assignSecondStagePoints(user.id, i)" class="points-btn" type="button" v-for="i in 11" :key="i" :content="'Przyznaj ' + i + ' punktów.'" v-tippy="{ placement : 'top',  arrow: true }">
                    <img src="@/assets/img/icons/devil.svg" alt="points"/>
                  </button>
                </p>
                <p class="points user-points" v-else><span>Punkty: {{ user.second_stage.points }}</span> <img src="@/assets/img/icons/devil.svg" alt="points"/></p>

                <p class="points" v-if="user.second_stage.points !== 0 && second_stage_show">
                  <span>Punkty:</span>
                  <button @click="assignSecondStagePoints(user.id, i)" class="points-btn" type="button" v-for="i in 11" :key="i" :content="'Przyznaj ' + i + ' punktów.'" v-tippy="{ placement : 'top',  arrow: true }">
                    <img src="@/assets/img/icons/devil.svg" alt="points"/>
                  </button>
                </p>

                <button @click="toggleSecondStage" class="main-button change-btn" v-if="user.second_stage.points !== 0">
                  <span v-if="second_stage_show">Anuluj zmianę</span>
                  <span v-if="!second_stage_show">Zmień ilość punktów</span>
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SideBar from "../Partials/SideBar";

export default {
  name: 'AdminSecondStage',
  components: {
    SideBar
  },
  mounted: function () {
    this.getSecondStages();
  },
  data() {
    return {
      second_stage_show: false,
      secondStages: []
    }
  },
  watch: {
    page (value) {
      this.disable = value > 20
    }
  },
  methods: {
    toggleSecondStage() {
      this.second_stage_show = !this.second_stage_show;
    },
    getSecondStages() {
      this.$http.get('all-users', {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
        }
      }).then(res => {
        res.data.forEach(post => {
          if (post.second_stage) {
            this.secondStages.push(post);
          }
        });
      })
    },
    assignSecondStagePoints(user_id, points) {
      const form = new FormData()

      form.set('user_id', user_id)
      form.set('points', points)

      this.$http.post('set-second-stage-points',
          form,
          {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'multipart/form-data',
            }
          })
          .then(({ data }) => {
            if (data.success) {
              this.secondStages = [];
              this.page = 1;
              this.getSecondStages();
              this.second_stage_show = false;
            }
          })
    }
  }
}
</script>
