<template>
  <section class="user-section user-second-stage">
    <div class="container">
      <div class="row">
        <div class="col-xl-2 col-lg-3 col-md-12">
          <SideBar></SideBar>
        </div>
        <div class="col-xl-8 col-lg-6 col-md-12">
          <div class="user-second-stage-content">
            <h1><img src="@/assets/img/titles/aktualne_zadanie_konkursowe.svg" alt="title" class="title-img"/></h1>
            <h2 class="head-h2">2. Przebierz się za ulubionego superbohatera</h2>
            <p class="desc">Prześlij nam zdjęcie! Aby zdobyć dodatkowy punkt, opublikuj je publicznie na swoim
              Instagramie z hashtagiem <span>#diablofamillychallenge</span>, oznacz nas oraz razem z załączonym zdjęciem
              prześlij link do postu na Instagramie.</p>

            <div class="welcome-content" v-if="!isActive">
              <p class="head">Maksymalna liczba diabełków do zdobycia:</p>

              <div class="devils">
                <img src="@/assets/img/icons/devil.svg" alt="devil" class="devil-img" v-for="i in 11" :key="i"/>
              </div>

              <button @click="activeStage" type="button" class="main-button start-stage">Biorę udział</button>
            </div>

            <div class="stage-content" v-if="isActive">

              <div class="errors">
                <p v-if="error" class="error-label">{{ error }}</p>
                <ul class="errors-list">
                  <li class="error-label" v-for="(err, index) in errors" :key="index">{{ err[0] }}</li>
                </ul>
              </div>

              <form enctype="multipart/form-data" @submit.prevent="answer">
                <div class="dropbox" v-if="isInitial">
                  <input type="file" @change="filesChange" accept="image/*" class="input-file">
                  <p v-if="isInitial">
                    <img src="@/assets/img/icons/image.svg" alt="image" class="image-img"/> Przeciągnij i upuść zdjęcie <span>TUTAJ</span><br>lub kliknij <span class="add-file">DODAJ PLIK</span>
                  </p>
                </div>

                <div v-if="isSuccess">
                  <h2 class="uploaded-file-title">Pomyślnie załadowano zdjęcie.</h2>
                  <p>
                    <a href="javascript:void(0)" @click="reset()" class="reset main-button">Wybierz inne zdjęcie.</a>
                  </p>
                  <img v-if="fileURL" :src="fileURL" :alt="fileName" class="uploaded-file"/>
                </div>

                <label for="link" class="link-label">Opublikowałeś zdjęcie na Instagramie? Wklej link, aby uzyskać dodatkowego diabełka:</label>
                <input type="text" id="link" v-model="link"/>

                <div class="stage-buttons">
                  <button @click="activeStage" type="button" class="main-button">Anuluj</button>
                  <button type="submit" class="main-button">Odpowiedz</button>
                </div>
              </form>

            </div>
          </div>
        </div>
        <div class="offset-xl-2 offset-lg-3 d-md-none"></div>
      </div>
    </div>
  </section>
</template>

<script>
import SideBar from "../Partials/SideBar";

const STATUS_INITIAL = 0, STATUS_SUCCESS = 2;

export default {
  name: 'UserSecondStage',
  components: {
    SideBar
  },
  data() {
    return {
      id: '',
      link: '',
      error: '',
      errors: [],
      file: '',
      fileURL: '',
      fileName: '',
      isActive: false,
      currentStatus: null,
      secondStage: []

    }
  },
  mounted() {
    this.reset();
    this.getId();
    this.protectedDate();
  },
  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    }
  },
  methods: {
    activeStage() {
      this.isActive = !this.isActive;
    },
    protectedDate() {
      let now = new Date();
      let start = new Date('2021-05-31');

      if (now.getTime() > start.getTime()) {
        this.$router.push({ name: 'userTasks' });
      }
    },
    reset() {
      this.currentStatus = STATUS_INITIAL;
      this.fileURL = '';
    },
    filesChange(e) {
      this.currentStatus = STATUS_SUCCESS;

      this.file = e.target.files[0];
      this.fileURL = URL.createObjectURL(this.file);
      this.fileName = this.file.name;
    },
    answer() {
      const form = new FormData()

      form.set('user_id', this.id)
      form.set('image', this.file)
      form.set('link', this.link)

      this.$http.post('second-stage',
          form,
          {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'multipart/form-data',
            }
          })
          .then(({ data }) => {
            if (data.success) {
              this.$router.push({ name: 'userThanks' });
            } else {
              this.error = data.error;
              this.errors = data.errors;
            }
          })
    },
    getId() {
      this.$http.get('me', {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
        }
      }).then(({ data }) => {
        this.id = data.me.id;

        if (data.me.second_stage) {
          this.$router.push({ name: 'userTasks' });
        }
      })
    }
  }
}
</script>
