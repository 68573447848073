<template>
  <div class="sidebar-content">
    <div class="sidebar-desktop">
      <MenuLinks></MenuLinks>
    </div>
  </div>
</template>

<script>
import MenuLinks from "../Components/MenuLinks";

export default {
  name: 'SideBar',
  components: {
    MenuLinks
  }
}
</script>
