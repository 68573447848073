<template>
  <section class="user-section user-start">
    <div class="container">
      <div class="row">
        <div class="col-xl-2 col-lg-3 col-md-12">
          <SideBar></SideBar>
        </div>
        <div class="col-xl-8 col-lg-6 col-md-12">
          <div class="user-start-content">
            <h1><img src="@/assets/img/titles/wez_udzial_i_wygraj.svg" alt="title" class="title-img"/></h1>
            <p class="head">dołącz do <span>#diablofamilychallenge</span></p>
            <p class="desc">Przygotowaliśmy trzy zadania konkursowe, w których sprawdzimy i ocenimy Twoją kreatywność oraz znajomość naszej marki. Weź udział w naszej zabawie i graj o niezwykłe nagrody, m. in. fotel gamingowy Diablo Chairs. Powodzenia!</p>

            <div class="row">
              <div class="col-xl-4 col-lg-12">
                <div class="award-box">
                  <div class="content">
                    <div class="center">
                      <h2>I miejsce</h2>
                      <p>Na zwycięzcę czeka wybrany fotel gamingowy, mata antypoślizgowa oraz cztery koszulki Diablo Chairs.</p>
                    </div>
                  </div>
                  <img src="@/assets/img/images/small-award1.png" alt="award" class="award-img"/>
                </div>
              </div>
              <div class="col-xl-4 col-lg-12">
                <div class="award-box">
                  <div class="content">
                    <div class="center">
                      <h2>II miejsce</h2>
                      <p>Zdobywcę drugiego miejsca nagrodzimy wybranym fotelem gamingowym, podkładką LED oraz czterema koszulkami Diablo Chairs.</p>
                    </div>
                  </div>
                  <img src="@/assets/img/images/small-award3.png" alt="award" class="award-img"/>
                </div>
              </div>
              <div class="col-xl-4 col-lg-12">
                <div class="award-box">
                  <div class="content">
                    <div class="center">
                      <h2>III miejsce</h2>
                      <p>Trzecie miejsce na podium to pewność, że w Twoje ręce trafi nasze biurko gamingowe, wybrany workoplecak oraz cztery koszulki Diablo Chairs.</p>
                    </div>
                  </div>
                  <img src="@/assets/img/images/small-award2.png" alt="award" class="award-img"/>
                </div>
              </div>
            </div>

            <router-link :to="{ name: 'userTasks' }" class="main-button go-to-tasks">Biorę udział</router-link>
          </div>
        </div>
        <div class="offset-xl-2 offset-lg-3 d-md-none"></div>
      </div>
    </div>
  </section>
</template>

<script>
import SideBar from "../Partials/SideBar";
export default {
  name: 'UserStart',
  components: {SideBar}
}
</script>
