<template>
  <section class="login-section">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <LoginForm></LoginForm>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import LoginForm from "../Components/LoginForm";

export default {
  name: 'AdminLogin',
  components: {
    LoginForm
  }
}
</script>
