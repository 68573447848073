<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="header-content">
          <router-link :to="{ name: 'start' }">
            <img src="@/assets/img/icons/family-logo.svg" class="header-logo" alt="diablochairs-logo"/>
          </router-link>

          <div class="time-line" v-if="loggedIn || adminLoggedIn">
            <div class="line"></div>

            <img src="@/assets/img/icons/devil.svg" alt="devil" class="devil-img"
              :class="{
                'first': first,
                'half-second': halfSecond,
                'second': second,
                'half-third': halfThird,
                'third': third,
                'half-end': halfEnd,
                'end': end,
                'half-summary': halfSummary,
                'summary': summary
            }"
            />

            <div class="time-box first">
              <span class="date">24.05</span>
              <span class="dot"></span>
              <span class="name">I etap</span>
            </div>

            <div class="time-box second">
              <span class="date">31.05</span>
              <span class="dot"></span>
              <span class="name">II etap</span>
            </div>

            <div class="time-box third">
              <span class="date">09.06</span>
              <span class="dot"></span>
              <span class="name">III etap</span>
            </div>

            <div class="time-box end">
              <span class="date">20.06</span>
              <span class="dot"></span>
              <span class="name">Zakończenie</span>
            </div>

            <div class="time-box summary">
              <span class="date">30.06</span>
              <span class="dot"></span>
              <span class="name">Wyniki</span>
            </div>
          </div>

          <button class="mobile-button menu-btn" v-b-toggle.sidebar-right>
            <img src="@/assets/img/icons/menu.svg" alt="mobile-menu"/>
          </button>

          <MobileMenu></MobileMenu>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MobileMenu from "./MobileMenu";
import { login } from "../../utils/mixins";

export default {
  name: 'Header',
  mixins: [login],
  components: {
    MobileMenu
  },
  data() {
    return {
      first: false,
      halfSecond: false,
      second: false,
      halfThird: false,
      third: false,
      halfEnd: false,
      end: false,
      halfSummary: false,
      summary: false
    }
  },
  mounted: function () {
    this.loadDates();
  },
  methods: {
    loadDates() {
      let now = new Date();

      let first = new Date('2021-05-24');
      let halfSecond = new Date('2021-05-28');
      let second = new Date('2021-05-31');
      let halfThird = new Date('2021-06-05');
      let third = new Date('2021-06-09');
      let halfEnd = new Date('2021-06-15');
      let end = new Date('2021-06-20');
      let halfSummary = new Date('2021-06-25');
      let summary = new Date('2021-06-30');

      if (now.getTime() >= first.getTime() && now.getTime() < halfSecond.getTime()) {
        this.first = true;
      } else if (now.getTime() >= halfSecond.getTime() && now.getTime() < second.getTime()) {
        this.halfSecond = true;
      } else if (now.getTime() >= second.getTime() && now.getTime() < halfThird.getTime()) {
        this.second = true;
      } else if (now.getTime() >= halfThird.getTime() && now.getTime() < third.getTime()) {
        this.halfThird = true;
      } else if (now.getTime() >= third.getTime() && now.getTime() < halfEnd.getTime()) {
        this.third = true;
      } else if (now.getTime() >= halfEnd.getTime() && now.getTime() < end.getTime()) {
        this.halfEnd = true;
      } else if (now.getTime() >= end.getTime() && now.getTime() < halfSummary.getTime()) {
        this.end = true;
      }  else if (now.getTime() >= halfSummary.getTime() && now.getTime() < summary.getTime()) {
        this.halfSummary = true;
      }  else if (now.getTime() >= summary.getTime()) {
        this.summary = true;
      }
    },
  }
}
</script>