<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="header-content">
          <router-link :to="{ name: 'start' }">
            <img src="@/assets/img/icons/family-logo.svg" class="header-logo" alt="diablochairs-logo"/>
          </router-link>

          <div class="text">
            <!--
            <p class="link" v-if="isLogin">Nie posiadasz konta? <router-link :to="{ name: 'register' }">Zarejestruj się</router-link></p>
            -->
            <p class="link" v-if="isRegister">Masz już konto? <router-link :to="{ name: 'login' }">Zaloguj się</router-link></p>
            <p class="date">24.05.2021 - 30.06.2021</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StartHeader',
  computed: {
    isLogin() {
      return this.$route.name === 'login'
    },
    isRegister() {
      return this.$route.name === 'register'
    },
  }
}
</script>