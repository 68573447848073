<template>
  <section class="admin-user">
    <div class="container">
      <div class="row">
        <div class="col-xl-2 col-lg-3 col-md-12">
          <SideBar></SideBar>
        </div>
        <div class="col-xl-10 col-lg-9 col-md-12">
          <div class="user-box">

            <div class="row">
              <div class="col-sm-6 col-12">
                <h3>Imię: {{ user.firstname }}</h3>
                <h3>Login: {{ user.login }}</h3>
                <h3>Adres e-mail: {{ user.email }}</h3>
                <h3>
                  Zgoda na newsletter:
                  <span v-if="user.newsletter">Tak</span>
                  <span v-else>Nie</span>
                </h3>
              </div>
              <div class="col-sm-6 col-12">
                <h3>Nazwisko: {{ user.lastname }}</h3>
                <h3>Nick: {{ user.nick }}</h3>
                <h3>Numer telefonu: {{ user.phone }}</h3>
                <h3><button class="delete-btn" content="Usuń tego użytkownika." v-tippy="{ placement : 'top',  arrow: true }" @click="showDeleteUserModal">Usuń użytkownika</button></h3>
              </div>
            </div>

            <div class="divid"></div>

            <div class="row">
              <div class="col-sm-6 col-12">
                <h3 class="points">Etapy: {{ allStages() }}/3</h3>
              </div>
              <div class="col-sm-6 col-12">
                <p class="points user-points"><span>Punkty: {{ summary }}</span> <img src="@/assets/img/icons/devil.svg" alt="points"/></p>
              </div>
            </div>

            <div class="divid"></div>
            <h3>Etap 1</h3>

            <p class="desc" v-if="!first_stage">Brak danych odnośnie tego etapu.</p>

            <div v-if="first_stage">
              <p class="desc">{{ first_stage.description }}</p>
              <p class="points" v-if="first_stage.points === 0">
                <span>Punkty:</span>
                <button @click="assignFirstStagePoints(user.id, i)" class="points-btn" type="button" v-for="i in 11" :key="i" :content="'Przyznaj ' + i + ' punktów.'" v-tippy="{ placement : 'top',  arrow: true }">
                  <img src="@/assets/img/icons/devil.svg" alt="points"/>
                </button>
              </p>
              <p class="points" v-else><span>Punkty: {{ first_stage.points }}</span> <img src="@/assets/img/icons/devil.svg" alt="points"/></p>

              <p class="points" v-if="first_stage.points !== 0 && first_stage_show">
                <span>Punkty:</span>
                <button @click="assignFirstStagePoints(user.id, i)" class="points-btn" type="button" v-for="i in 11" :key="i" :content="'Przyznaj ' + i + ' punktów.'" v-tippy="{ placement : 'top',  arrow: true }">
                  <img src="@/assets/img/icons/devil.svg" alt="points"/>
                </button>
              </p>

              <button @click="toggleFirstStage" class="main-button change-btn" v-if="first_stage.points !== 0">
                <span v-if="first_stage_show">Anuluj zmianę</span>
                <span v-if="!first_stage_show">Zmień ilość punktów</span>
              </button>
            </div>

            <div class="divid"></div>
            <h3>Etap 2</h3>

            <p class="desc" v-if="!second_stage">Brak danych odnośnie tego etapu.</p>

            <div v-if="second_stage">
              <img :src="'https://challenge-api.fkowalik.pl/' + second_stage.image" alt="instagram" class="user-img"/>
              <p class="link" v-if="second_stage.link">Instagram: <a :href="second_stage.link" target="_blank" class="instagram-link" content="Otwórz link w nowym oknie." v-tippy="{ placement : 'top',  arrow: true }">Otwórz link</a></p>
              <p class="link" v-if="!second_stage.link">Instagram: <span>Brak linku</span></p>
              <p class="points" v-if="second_stage.points === 0">
                <span>Punkty:</span>
                <button @click="assignSecondStagePoints(user.id, i)" class="points-btn" type="button" v-for="i in 11" :key="i" :content="'Przyznaj ' + i + ' punktów.'" v-tippy="{ placement : 'top',  arrow: true }">
                  <img src="@/assets/img/icons/devil.svg" alt="points"/>
                </button>
              </p>
              <p class="points" v-else><span>Punkty: {{ second_stage.points }}</span> <img src="@/assets/img/icons/devil.svg" alt="points"/></p>

              <p class="points" v-if="second_stage.points !== 0 && second_stage_show">
                <span>Punkty:</span>
                <button @click="assignSecondStagePoints(user.id, i)" class="points-btn" type="button" v-for="i in 11" :key="i" :content="'Przyznaj ' + i + ' punktów.'" v-tippy="{ placement : 'top',  arrow: true }">
                  <img src="@/assets/img/icons/devil.svg" alt="points"/>
                </button>
              </p>

              <button @click="toggleSecondStage" class="main-button change-btn" v-if="second_stage.points !== 0">
                <span v-if="second_stage_show">Anuluj zmianę</span>
                <span v-if="!second_stage_show">Zmień ilość punktów</span>
              </button>
            </div>

            <div class="divid"></div>
            <h3>Etap 3</h3>

            <p class="desc" v-if="!third_stage">Brak danych odnośnie tego etapu.</p>

            <div v-if="third_stage">
              <p>
                1-{{ third_stage.first }};
                2-{{ third_stage.second }};
                3-{{ third_stage.third }};
                4-{{ third_stage.fourth }};
                5-{{ third_stage.fifth }};
                6-{{ third_stage.sixth }};
                7-{{ third_stage.seventh }};
                8-{{ third_stage.eight }};
                9-{{ third_stage.ninth }};
                10-{{ third_stage.tenth }}
              </p>
              <p class="points"><span>Punkty: {{ thirdPoints }}</span> <img src="@/assets/img/icons/devil.svg" alt="points"/></p>
            </div>

            <router-link :to="{ name: 'adminUsers' }" class="main-button back">Powrót</router-link>

            <vue-modal name="deleteUserModal" class="delete-user-modal">
              <h2 slot="header">Czy napewno chcesz usunąć użytkownika <span>{{ user.nick }}</span> ?</h2>

              <div class="modal-buttons">
                <button class="main-button" @click="closeDeleteUserModal">Powrót</button>
                <button class="main-button accept" @click="deleteUser(user.id)">Usuń</button>
              </div>

            </vue-modal>

          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SideBar from "../Partials/SideBar";
import { User, FirstStage, SecondStage, ThirdStage } from "../../models/models";

export default {
  name: 'AdminUser',
  components: {
    SideBar
  },
  data() {
    return {
      user: User,
      first_stage: FirstStage,
      second_stage: SecondStage,
      third_stage: ThirdStage,
      id: this.$route.params.id,
      stages: 0,
      thirdPoints: 0,
      summary: 0,
      first_stage_show: false,
      second_stage_show: false
    }
  },
  mounted: function () {
    this.getUser();
  },
  methods: {
    toggleFirstStage() {
      this.first_stage_show = !this.first_stage_show;
    },
    toggleSecondStage() {
      this.second_stage_show = !this.second_stage_show;
    },
    showDeleteUserModal() {
      this.$modals.show("deleteUserModal");
    },
    closeDeleteUserModal() {
      this.$modals.hide("deleteUserModal");
    },
    allPoints() {
      let firstPoints = 0;
      let secondPoints = 0;
      let thirdPoints = 0;

      if (this.first_stage) {
        firstPoints = this.first_stage.points;
        this.stages++;
      }

      if (this.second_stage) {
        secondPoints = this.second_stage.points;
        this.stages++;
      }

      if (this.third_stage) {
        thirdPoints = this.third_stage.first_point + this.third_stage.second_point + this.third_stage.third_point + this.third_stage.fourth_point +
            this.third_stage.fifth_point + this.third_stage.sixth_point + this.third_stage.seventh_point + this.third_stage.eight_point +
            this.third_stage.ninth_point + this.third_stage.tenth_point;
        this.stages++;
        this.thirdPoints = thirdPoints;
      }

      this.summary = firstPoints + secondPoints + thirdPoints;

      return this.summary;
    },
    allStages() {
      let stages = 0;

      if (this.first_stage) {
        stages++;
      }

      if (this.second_stage) {
        stages++;
      }

      if (this.third_stage) {
        stages++;
      }

      return stages;
    },
    getUser() {
      this.$http.get('users/' + this.id, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
        }
      }).then(({ data }) => {
       // User
       this.user.id = data.user.id;
       this.user.firstname = data.user.firstname;
       this.user.lastname = data.user.lastname;
       this.user.login = data.user.login;
       this.user.nick = data.user.nick;
       this.user.email = data.user.email;
       this.user.phone = data.user.phone;
       this.user.agree = data.user.agree;
       this.user.marketing = data.user.marketing;
       this.user.newsletter = data.user.newsletter;

       // Stages
       this.first_stage = data.user.first_stage;
       this.second_stage = data.user.second_stage;
       this.third_stage = data.user.third_stage;

        this.allPoints();

      }).catch((error) => {
        if (error.response.status === 404) {
          this.$router.push({ name: 'adminUsers' });
        }
      })
    },
    assignFirstStagePoints(user_id, points) {
      const form = new FormData()

      form.set('user_id', user_id)
      form.set('points', points)

      this.$http.post('set-first-stage-points',
          form,
          {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'multipart/form-data',
            }
          })
          .then(({ data }) => {
            if (data.success) {
              this.getUser();
              this.first_stage_show = false;
            }
          })
    },
    assignSecondStagePoints(user_id, points) {
      const form = new FormData()

      form.set('user_id', user_id)
      form.set('points', points)

      this.$http.post('set-second-stage-points',
          form,
          {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'multipart/form-data',
            }
          })
          .then(({ data }) => {
            if (data.success) {
              this.getUser();
              this.second_stage_show = false;
            }
          })
    },
    deleteUser(user_id) {
      this.$http.delete('users/' + user_id,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
              "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
            }
          })
          .then(({ data }) => {
            if (data.success) {
              this.closeDeleteUserModal();
              this.$router.push({ name: 'adminUsers' });
            }
          })
    }
  }
}
</script>
